const Search = [
  {
    name: "CAPRI ISLAND",
    link: "/cruise/capri-island",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "AMALFI COAST",
    link: "/cruise/amalfi-coast",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "CAPRI ISLAND & POSITANO",
    link: "/cruise/capri-positano",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "ISCHIA & PROCIDA",
    link: "/cruise/ischia-procida",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "SUNSET CRUISE",
    link: "/cruise/sunset-cruise",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "DUFOUR 390 SAILING YACHT",
    link: "/cruise/dufour-390-sailing-yacht",
    isSearchable: true,
    isAnchor: false
},
  
  {
    name: "IN-VILLA PIZZA CHEF",
    link: "/in-villa#InVillaPizzaChef",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "IN-VILLA GOURMET DINING",
    link: "/in-villa#InVillaGourmetDinner",
    isSearchable: true,
    isAnchor: true
  },
  // {
  //   name: "SUNSET LOUNGE AND MIXOLOGY",
  //   link: "/in-villa#SunsetLoungeAndMixology",
  //   isSearchable: true,
  //   isAnchor: true
  // },
  {
    name: "IN-VILLA COOKING CLASS",
    link: "/in-villa#InVillaCookingClass",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "ORTO IN TAVOLA COOKING CLASS",
    link: "/culinary-experiences#OrtoInTavola",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "WINE TASTING",
    link: "/culinary-experiences#WineTasting",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "OLIVE OIL TASTING",
    link: "/culinary-experiences#OilTasting",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "MOZZARELLA MAKING",
    link: "/culinary-experiences/country-lifestyle/mozzarella-making",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "CRAFTING LIMONCELLO LIQUOR",
    link: "/culinary-experiences/country-lifestyle/crafting-mozzarella-limoncello-liquor",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "COUNTRY LIFESTYLE",
    link: "/culinary-experiences#CountryLifestyle",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "AL FRESCO PICNIC EXPERIENCE",
    link: "/culinary-experiences/country-lifestyle/al-fresco-picnic",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "POMPEI & VESUVIUS",
    link: "/cultural-activities/pompei-vesuvius",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "POMPEI OR VESUVIUS GETAWAY",
    link: "/cultural-activities/vesuvius",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "THE AMALFI COAST SCENIC ROUTE",
    link: "/cultural-activities/scenic-route",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "PATH OF THE GODS",
    link: "/nature-adventure/path-of-the-gods",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "THE MILLS VALLEY",
    link: "/nature-adventure/the-mills-valley",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "PUNTA CAMPANELLA & MONTE SAN COSTANZO",
    link: "/nature-adventure/punta-campanella-monte-sancostanzo",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "BAY OF JERANTO",
    link: "/nature-adventure/bay-of-jeranto",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "FREEDIVING COURSE",
    link: "/nature-adventure/freediving",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "IN-VILLA BREAKFAST",
    link: "/in-villa#InVillaBreakfast",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "PERSONAL GROCERY SHOPPER",
    link: "/in-villa#PersonalGroceryShopper",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "MASSAGE",
    link: "/wellness#Massage",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "FACIALS & BEAUTY TREATMENTS",
    link: "/wellness#FacialsBeautyTreatments",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "PERSONAL TRAINER",
    link: "/wellness#PersonalTrainer",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "PRIVATE TRANSFERS",
    link: "/private-transfers",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "WEDDING EVENTS",
    link: "/memorable-experiences#wedding-events",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "YOGA RETREATS",
    link: "/memorable-experiences#yoga-retreats",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "CAR & VESPA SERVICES",
    link: "/memorable-experiences#car-vespa-services",
    isSearchable: true,
    isAnchor: true
  },
  {
    name: "AMALFI COAST SHARED CRUISE",
    link: "/cruise/amalfi-coast-shared-cruise",
    isSearchable: true,
    isAnchor: false
  },
  {
    name: "CAPRI ISLAND SHARED CRUISE",
    link: "/cruise/capri-island-shared-cruise",
    isSearchable: true,
    isAnchor: false
  },
  
  
];

export default Search;