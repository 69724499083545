import React from "react";
import companyLogo from '../content/img/logo-w.png';
import companyLogoWhite from '../content/img/logo.png';
import companyLogoMobile from '../content/img/logo-mobile-w.png';
import companyLogoWhiteMobile from '../content/img/logo-mobile.png';
import MenuMobile from '../utilities/MenuMobile';
import Nav from 'react-bootstrap/Nav';


const mobile = window.matchMedia("screen and (max-width: 767px)").matches;
const tablet = window.matchMedia("screen and (max-width: 991px)").matches;

class Header extends React.Component {

  render() {
    return (
      <>
        <header className={this.props.bg === "Transparent" ? "" : "bg-white"}>
          <div className="container-fluid p-80 p-xs-header">
            <div className="row pt-5 pb-3">
              <div className="col-12 text-center-m">
                {
                  mobile || tablet ?
                    <>
                      <a className="pHeading" href="/">
                        <img width="200" src={this.props.bg === "Transparent" ? companyLogoMobile : companyLogoWhiteMobile} alt="Feeling Italy logo" />
                      </a>
                      <div className="menu-icon float-end" onClick={() => {
                        document.getElementById('Menu').classList.add('active');
                        document.getElementsByTagName('Body')[0].classList.add('menuOpen');
                        document.getElementsByTagName('Html')[0].style.overflow = "hidden";
                        document.getElementById('root').style.overflow = "hidden";
                      }}>
                        <span className="barOne">
                        </span>
                        <span className="barTwo">
                        </span>
                        <span className="barThree">
                        </span>
                      </div>
                      <div id="Menu" className="nav-elements">
                        <a className="pHeading" href="/">
                          <img width="200" src={this.props.bg === "Transparent" ? companyLogoMobile : companyLogoWhiteMobile} alt="Feeling Italy logo" />
                        </a>
                        <div className="menu-icon float-end" onClick={() => {
                          document.getElementById('Menu').classList.remove('active');
                          document.getElementsByTagName('Body')[0].classList.remove('menuOpen');
                          document.getElementsByTagName('Html')[0].style.overflow = "inherit";
                          document.getElementById('root').style.overflow = "inherit";
                        }}>
                          <span className="closeMenu">
                          </span>
                        </div>
                        <div className="overflowLand">
                          <ul className="pt-5">
                            {
                              MenuMobile.map((item, index) => {
                                return (
                                  item.position === "Header" ?
                                    <li key={index}><Nav.Link href={item.href} key={"Menu" + index} className={item.class}>{item.text}</Nav.Link></li>
                                    :
                                    null
                                )
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <a className="pHeading" href="/">
                        <img width="187" src={this.props.bg === "Transparent" ? companyLogo : companyLogoWhite} alt="Feeling Italy logo" />
                      </a>
                      <a className={this.props.bg === "Transparent" ? "lineEffect text-white me-3 float-end" : "lineEffectBlack text-black me-3 float-end"} href="/contact">Contact us</a>
                    </>
                }
              </div>
            </div>
          </div>
        </header >
      </>
    )
  }
}

export default Header;
