import React from "react";
import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

class CTA extends React.Component {
  render() {
    return (
      <>
        <section className="my-5 cta">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-7 col-12 bg-black padding2-5 my-5 ctaCustomDisplay">
                <span className='mb-3 text-white ctaText'>Contact our concierge to book these experiences
                </span>
                <a className='link text-white float-end effectArrow' href="/reservation-form">Reservation <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></a>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default CTA;
