//Inside this const we have the single experience (all information: inside and external carousel)
const ExperiencesInternal = [
  {
    id: 13,
    category: "Cruise",
    categoryInternal: "Dufour390SailingYacht",
    imgCardParent: require('../content/img/cruise/dufour-390-sailing-yacht/dufour-390-sailing-yacht-1.jpg'),
    serviceName: "DUFOUR 390 SAILING YACHT",
    description: "Cruise along the Amalfi Coast, Capri, and the nearby islands aboard our Dufour 390 Grand Large, an extraordinary sailing yacht seamlessly combining performance, comfort, and elegance. With a length of 39 feet, this vessel boasts well-designed and spacious interiors capable of accommodating up to eight guests. The sleek lines and modern shape ensure exceptional stability and comfort at sea. Immerse yourself in crystal-clear waters, discover hidden island bays, and marvel at breathtaking natural landscapes aboard this exclusive sailing yacht. The cruise itinerary with Dufour 390 Grand Large is tailor-made for our guests. Guests may request a private chef on board.",
    shortDescription: "Cruise along the Amalfi Coast, Capri, and the nearby islands aboard our Dufour 390 Grand Large...",
    serviceNameParent: "Cruise",
    linkParentCategory: "/cruise#return",
    linkImgOne: require('../content/img/cruise/dufour-390-sailing-yacht/dufour-390-sailing-yacht-1.jpg'),
    linkImgTwo: require('../content/img/cruise/dufour-390-sailing-yacht/dufour-390-sailing-yacht-2.jpg'),
    linkImgThree: require('../content/img/cruise/dufour-390-sailing-yacht/dufour-390-sailing-yacht-3.jpg'),
    // TitleLeft1: "Duration",
    TitleLeft2: "Price",
    // duration: "3 HOURS",
    price: "PRICE UPON REQUEST",
    // htmlOne: "<h5 class='subTitle-18 borderBox'>CRUISE HIGHLIGHT</h5><ul><li>Scenic views of Positano, Furore, and Conca dei Marini</li></ul>",
    htmlTwo: "<h5 class='subTitle-18 borderBox'>INCLUDED SERVICES</h5><ul><li>Soft drinks and light snacks</li><li>Skipper</li><li>Towels</li><li>Snorkeling gear</li><li>VAT</li><li>Fuel</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/cruise/dufour-390-sailing-yacht",
    isActiveCarousel: false,
  },
  {
    id: 1,
    category: "Cruise",
    categoryInternal: "CapriIsland",
    serviceName: "Capri Island",
    imgCardParent: require('../content/img/cruise/capri/capri-island-2.jpg'),
    description: "Sail to the island of Capri famous for its Faraglioni towering rocks and the coastline wild beauty aboard our Sorrentine Gozzo or elegant yachts. Swim in crystal waters, explore the hidden bays of the island, the charming grottos, and savour fresh seafood at local tavernas for an essential Italian experience.",
    shortDescription: "Sail to the island of Capri famous for its Faraglioni towering rocks and the coastline wild beauty aboard our...",
    serviceNameParent: "Cruise",
    linkParentCategory: "/cruise#return",
    linkImgOne: require('../content/img/cruise/capri/capri-island-2.jpg'),
    linkImgTwo: require('../content/img/cruise/capri/capri-island-3.jpg'),
    linkImgThree: require('../content/img/cruise/capri/capri-island-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "FULL DAY 8 HOURS",
    price: "FROM €1.250,00",
    htmlOne: "<h5 class='subTitle-18 borderBox'>CRUISE HIGHLIGHT</h5><ul><li>Capri coastline, Faraglioni towering rocks, Natural Arch, Marina Piccola Bay, Blue Grotto, White Grotto, Punta Carena Lighthouse, Villa Malaparte, and the Saracen forts. </li><li>Lunch at a local taverna by the sea (Optional) </li><li>Drop-off on the island (3/4 Hours) </li><li>Capri port fee €100,00 </li></ul>",
    htmlTwo: "<h5 class='subTitle-18 borderBox'>INCLUDED SERVICES</h5><ul><li>Soft drinks and light snacks</li><li>Skipper</li><li>Towels</li><li>Snorkeling gear</li><li>Wi-Fi</li><li>VAT</li><li>Fuel</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/cruise/capri-island",
    isActiveCarousel: false,
  },
  {
    id: 2,
    category: "Cruise",
    categoryInternal: "AmalfiCoast",
    imgCardParent: require('../content/img/cruise/amalfi-coast/amalfi-coast-1.jpg'),
    serviceName: "Amalfi Coast",
    description: "Sail the Amalfi Coast in total comfort aboard our Sorrentine Gozzo or elegant yachts, as we head to the stunning archipelago of Li Galli Islands and the picturesque village of Positano with its chic boutiques, cafes, and art galleries. <br> Stop in Amalfi and at the Fjord of Furore to swim in azure waters, or explore the breathtaking coastline with pristine beaches and bays.",
    shortDescription: "Sail the Amalfi Coast in total comfort aboard our Sorrentine Gozzo or elegant yachts, as we head to the stunning...",
    serviceNameParent: "Cruise",
    linkParentCategory: "/cruise#return",
    linkImgOne: require('../content/img/cruise/amalfi-coast/amalfi-coast-2.jpg'),
    linkImgTwo: require('../content/img/cruise/amalfi-coast/amalfi-coast-3.jpg'),
    linkImgThree: require('../content/img/cruise/amalfi-coast/amalfi-coast-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "FULL DAY 8 HOURS",
    price: "FROM €1.350,00",
    htmlOne: "<h5 class='subTitle-18 borderBox'>CRUISE HIGHLIGHT</h5><ul><li>Amalfi coastline, Li Galli Islands, Positano, Amalfi, Fjord of Furore, Emerald Grotto</li><li>Lunch at a local taverna by the sea (Optional)</li><li>Drop-off in Positano and Amalfi (3/4 Hours)</li></ul>",
    htmlTwo: "<h5 class='subTitle-18 borderBox'>INCLUDED SERVICES</h5><ul><li>Soft drinks and light snacks</li><li>Skipper</li><li>Towels</li><li>Snorkeling gear</li><li>Wi-Fi</li><li>VAT</li><li>Fuel</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/cruise/amalfi-coast",
    isActiveCarousel: false,
  },
  {
    id: 3,
    category: "Cruise",
    categoryInternal: "CapriPositano",
    imgCardParent: require('../content/img/cruise/capri-positano/capri-positano-2.jpg'),
    serviceName: "Capri Island & Positano",
    description: "Cruise the coastline of Capri aboard our Sorrentine Gozzo or elegant yachts, where you will swim in crystal waters, explore the hidden bays of the island, and savour fresh seafood at local tavernas for an essential Italian experience. <br> The journey continues along breathtaking natural sceneries, past Punta Campanella, the bays of Ieranto and Nerano, and the awe-inspring views of Positano.",
    shortDescription: "Cruise the coastline of Capri aboard our Sorrentine Gozzo or elegant yachts, where you will swim in crystal waters...",
    serviceNameParent: "Cruise",
    linkParentCategory: "/cruise#return",
    linkImgOne: require('../content/img/cruise/capri-positano/capri-positano-2.jpg'),
    linkImgTwo: require('../content/img/cruise/capri-positano/capri-positano-3.jpg'), 
    linkImgThree: require('../content/img/cruise/capri-positano/capri-positano-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "FULL DAY 8 HOURS",
    price: "FROM €1.350,00",
    htmlOne: "<h5 class='subTitle-18 borderBox'>CRUISE HIGHLIGHT</h5><ul><li>Faraglioni towering rocks, Natural Arch, Marina Piccola Bay, Blue Grotto, White Grotto, Bays of Ieranto and Nerano, Li Galli Islands, Positano</li><li>Lunch at a local taverna by the sea (Optional)</li></ul>",
    htmlTwo: "<h5 class='subTitle-18 borderBox'>INCLUDED SERVICES</h5><ul><li>Soft drinks and light snacks</li><li>Skipper</li><li>Towels</li><li>Snorkeling gear</li><li>Wi-Fi</li><li>VAT</li><li>Fuel</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/cruise/capri-positano",
    isActiveCarousel: false,
  },
  {
    id: 13,
    category: "Cruise",
    categoryInternal: "AmalfiCoastShared",
    imgCardParent: require('../content/img/cruise/amalfi-coast-shared/amalfi-coast-shared-2.jpg'),
    serviceName: "Amalfi Coast Shared Cruise",
    description: "Sail the Amalfi Coast in total comfort aboard our Sorrentine Gozzo, as we head to the stunning archipelago of Li Galli Islands and the picturesque village of Positano with its chic boutiques, cafes, and art galleries. Stop in Amalfi and at the Fjord of Furore to swim in azure waters, or explore the breathtaking coastline with pristine beaches and bays.",
    shortDescription: "Sail the Amalfi Coast in total comfort aboard our Sorrentine Gozzo, as we head to the stunning archipelago of...",
    serviceNameParent: "Cruise",
    linkParentCategory: "/cruise#return",
    linkImgOne: require('../content/img/cruise/amalfi-coast-shared/amalfi-coast-shared-2.jpg'),
    linkImgTwo: require('../content/img/cruise/amalfi-coast-shared/amalfi-coast-shared-3.jpg'),
    linkImgThree: require('../content/img/cruise/amalfi-coast-shared/amalfi-coast-shared-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "FULL DAY 8 HOURS",
    price: "FROM €154,00 PER PERSON",
    htmlOne: "<h5 class='subTitle-18 borderBox'>CRUISE HIGHLIGHT</h5><ul><li>Amalfi coastline, Li Galli Islands, Positano, Amalfi, Fjord of Furore</li><li>Drop-off in Positano (1 Hour 30 Minutes)</li><li>Drop-off in Amalfi (1 Hour 30 Minutes)</li><li>Shared boat capacity 12 people</li></ul>",
    htmlTwo: "<h5 class='subTitle-18 borderBox'>INCLUDED SERVICES</h5><ul><li>Soft drinks and light snacks</li><li>Skipper</li><li>Towels</li><li>Snorkeling gear</li><li>Wi-Fi</li><li>VAT</li><li>Fuel</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/cruise/amalfi-coast-shared-cruise",
    isActiveCarousel: false,
  },
  {
    id: 14,
    category: "Cruise",
    categoryInternal: "CapriIslandShared",
    imgCardParent: require('../content/img/cruise/capri-island-shared/capri-island-shared-2.jpg'),
    serviceName: "Capri Island Shared Cruise",
    description: "Sail to the island of Capri famous for its Faraglioni towering rocks and the coastline wild beauty aboard our Sorrentine Gozzo. Stroll the enchanting streets of the island and take the time to contemplate the natural wonders at your own pace. Follow your way to the iconic Piazzetta and Via Camerelle for an exclusive shopping experience and revive your senses among celebrated landscapes and Capri lifestyle. Continue to sail to the hidden bays of the island, the charming grottos, and create meaningful memories along the way.",
    shortDescription: "Sail to the island of Capri famous for its Faraglioni towering rocks and the coastline wild beauty aboard...",
    serviceNameParent: "Cruise",
    linkParentCategory: "/cruise#return",
    linkImgOne: require('../content/img/cruise/capri-island-shared/capri-island-shared-2.jpg'),
    linkImgTwo: require('../content/img/cruise/capri-island-shared/capri-island-shared-3.jpg'),
    linkImgThree: require('../content/img/cruise/capri-island-shared/capri-island-shared-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "FULL DAY 8 HOURS",
    price: "FROM €129,00 PER PERSON",
    htmlOne: "<h5 class='subTitle-18 borderBox'>CRUISE HIGHLIGHT</h5><ul><li>Capri coastline, Faraglioni towering rocks, Natural Arch, Marina Piccola Bay, White Grotto, Green Grotto, Punta Carena Lighthouse, Villa Malaparte, and the Saracen forts.</li><li>Drop-off on the island (4 Hours)</li><li>Shared boat capacity 12 people</li></ul>",
    htmlTwo: "<h5 class='subTitle-18 borderBox'>INCLUDED SERVICES</h5><ul><li>Soft drinks and light snacks</li><li>Skipper</li><li>Towels</li><li>Snorkeling gear</li><li>Wi-Fi</li><li>VAT</li><li>Fuel</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/cruise/capri-island-shared-cruise",
    isActiveCarousel: false,
  },
  {
    id: 4,
    category: "Cruise",
    categoryInternal: "IschiaProcida",
    imgCardParent: require('../content/img/cruise/ischia-procida/ischia-procida-2.jpg'),
    serviceName: "Ischia & Procida",
    description: "Cruise to the island of Ischia, also known as the Green Island, for the stunning natural scenery, pristine beaches and thermal waters. Explore the bays with views of the Aragonese Castle, breathtaking cliffs and picturesque harbors. <br> Sail to the nearby island of Procida, the smallest of the Islands in the Bay of Naples, with a unique Mediterranean charm and savour fresh seafood at local fishermen tavernas for an essential Italian experience.",
    shortDescription: "Cruise to the island of Ischia, also known as the Green Island, for the stunning natural scenery, pristine beaches...",
    serviceNameParent: "Cruise",
    linkParentCategory: "/cruise#return",
    linkImgOne: require('../content/img/cruise/ischia-procida/ischia-procida-2.jpg'),
    linkImgTwo: require('../content/img/cruise/ischia-procida/ischia-procida-3.jpg'),
    linkImgThree: require('../content/img/cruise/ischia-procida/ischia-procida-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "FULL DAY 8 HOURS",
    price: "FROM €1.600,00",
    htmlOne: "<h5 class='subTitle-18 borderBox'>CRUISE HIGHLIGHT</h5><ul><li>Ischia coastline, Aragonese Castle, Lacco Ameno, Procida Picturesque Fishermen Village</li><li>Lunch at a local taverna by the sea (Optional)</li></ul>",
    htmlTwo: "<h5 class='subTitle-18 borderBox'>INCLUDED SERVICES</h5><ul><li>Soft drinks and light snacks</li><li>Skipper</li><li>Towels</li><li>Snorkeling gear</li><li>Wi-Fi</li><li>VAT</li><li>Fuel</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/cruise/ischia-procida",
    isActiveCarousel: false,
  },
  {
    id: 5,
    category: "Cruise",
    categoryInternal: "SunsetCruise",
    imgCardParent: require('../content/img/cruise/sunset-cruise/sunset-cruise-2.jpg'),
    serviceName: "Sunset Cruise",
    description: "Watch the sunset over the Mediterranean Sea during a two-hours cruise along the awe-inspiring natural landscapes of the Amalfi Coast. Enjoy an aperitif on board while sailing the charming bays and soak up the unique atmosphere of the coastline.",
    shortDescription: "Watch the sunset over the Mediterranean Sea during a two-hours cruise along the awe-inspiring natural landscapes...",
    serviceNameParent: "Cruise",
    linkParentCategory: "/cruise#return",
    linkImgOne: require('../content/img/cruise/sunset-cruise/sunset-cruise-2.jpg'),
    linkImgTwo: require('../content/img/cruise/sunset-cruise/sunset-cruise-3.jpg'),
    linkImgThree: require('../content/img/cruise/sunset-cruise/sunset-cruise-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "2 HOURS",
    price: "FROM €850,00",
    htmlOne: "<h5 class='subTitle-18 borderBox'>CRUISE HIGHLIGHT</h5><ul><li>San Montano riviera, Marina di Puolo, Pollio Felice Roman Villa, Bagni della Regina Giovanna</li></ul>",
    htmlTwo: "<h5 class='subTitle-18 borderBox'>INCLUDED SERVICES</h5><ul><li>Soft drinks and light snacks</li><li>Skipper</li><li>Towels</li><li>Snorkeling gear</li><li>VAT</li><li>Fuel</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/cruise/sunset-cruise",
    isActiveCarousel: false,
  },
  {
    id: 6,
    category: "NatureAdventure",
    categoryInternal: "PathOfGods",
    imgCardParent: require('../content/img/natureAdventure/path-of-the-gods/path-of-the-gods-2.jpg'),
    serviceName: "Path of the gods",
    description: "Explore the natural landscapes and picturesque villages of the Amalfi Coast hiking along the Sentiero degli Dei - Path of The Gods. Designed for hiking enthusiasts, this adventure experience offers spectacular trails that were once mule paths with awe-inspiring views of the coastline and the olive and lemon groves stretching along the stunning mountain scenery. <br> Led by a professional hiking guide, the journey begins from the hamlet of Nocelle near Positano passing hidden ancient Roman ruins and the local villages nestled among magnificent terraced vineyards above the sea.",
    shortDescription: "Explore the natural landscapes and picturesque villages of the Amalfi Coast hiking along...",
    serviceNameParent: "Nature & Adventure",
    linkParentCategory: "/nature-adventure#return",
    linkImgOne: require('../content/img/natureAdventure/path-of-the-gods/path-of-the-gods-2.jpg'),
    linkImgTwo: require('../content/img/natureAdventure/path-of-the-gods/path-of-the-gods-3.jpg'),
    linkImgThree: require('../content/img/natureAdventure/path-of-the-gods/path-of-the-gods-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "3 - 4 HOURS",
    price: "FROM €59,00 PER PERSON",
    htmlOne: "<h5 class='borderBox'>HIGHLIGHT</h5><ul><li>Nocelle hamlet, Colle Serra, nature preserve</li><li>Distance 7 km approx.</li></ul>",
    htmlTwo: "<h5 class='borderBox'>INCLUDED SERVICES</h5><ul><li>Private hiking guide</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/nature-adventure/path-of-the-gods",
    isActiveCarousel: false,
  },
  {
    id: 7,
    category: "NatureAdventure",
    categoryInternal: "TheMillsValley",
    imgCardParent: require('../content/img/natureAdventure/the-mills-valley/the-mills-valley-2.jpg'),
    serviceName: "The mills Valley",
    description: "Immerse yourself in uncontaminated nature paths with fascinating waterfalls and scenic landscapes. Led by a professional hiking guide, you’ll venture into the magnificent forest passing the medieval ancient ruins that were once paper mills where the renowned paper of Amalfi was produced. The journey begins from Pontone, the oldest town in the Amalfi Coast leading to the nature reserve of the Ferriere Valley where subtropical vegetation dominates the scene. <br> This adventure experience will reconnect you with history and the sublime nature.",
    shortDescription: "Immerse yourself in uncontaminated nature paths with fascinating waterfalls and scenic...",
    serviceNameParent: "Nature & Adventure",
    linkParentCategory: "/nature-adventure#return",
    linkImgOne: require('../content/img/natureAdventure/the-mills-valley/the-mills-valley-2.jpg'),
    linkImgTwo: require('../content/img/natureAdventure/the-mills-valley/the-mills-valley-3.jpg'),
    linkImgThree: require('../content/img/natureAdventure/the-mills-valley/the-mills-valley-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "3 - 4 HOURS",
    price: "FROM €230,00",
    htmlOne: "<h5 class='borderBox'>HIGHLIGHT</h5><ul><li>Pontone, Valle Delle Ferriere, Amalfi Paper Museum</li><li>Distance 5 km approx.</li></ul>",
    htmlTwo: "<h5 class='borderBox'>INCLUDED SERVICES</h5><ul><li>Private hiking guide</li><li>Soft drinks and light snacks</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/nature-adventure/the-mills-valley",
    isActiveCarousel: false,
  },
  {
    id: 8,
    category: "NatureAdventure",
    categoryInternal: "MarineHiking",
    imgCardParent: require('../content/img/natureAdventure/nature-marine-reserve-hiking/nature-marine-reserve-hiking-2.jpg'),
    serviceName: "Punta Campanella & Monte San Costanzo",
    description: "Explore the beautiful landscapes of the Amalfi Coast through the ancient Roman path surrounded by century-old olive groves and the evergreen trees. Led by a professional hiking guide, you’ll walk the path of Athena, a trail that shines a light on the history, myths and legends, leading you to the ruins of old temples, the Saracen towers, with magnificent views of the Bay of Mitigliano, Capri Island, and the natural scenery. <br> From Punta Campanella, the hike leads to Monte San Costanzo where an ancient monastery lies on the mountain peak with extraordinary 360-degree view of the islands and the Gulfs of Naples and Salerno.",
    shortDescription: "Explore the beautiful landscapes of the Amalfi Coast through the ancient Roman path...",
    serviceNameParent: "Nature & Adventure",
    linkParentCategory: "/nature-adventure#return",
    linkImgOne: require('../content/img/natureAdventure/nature-marine-reserve-hiking/nature-marine-reserve-hiking-2.jpg'),
    linkImgTwo: require('../content/img/natureAdventure/nature-marine-reserve-hiking/nature-marine-reserve-hiking-3.jpg'),
    linkImgThree: require('../content/img/natureAdventure/nature-marine-reserve-hiking/nature-marine-reserve-hiking-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "4 HOURS",
    price: "FROM €230,00",
    htmlOne: "<h5 class='borderBox'>HIGHLIGHT</h5><ul><li>Termini, Punta Campanella, Monte San Costanzo</li><li>Distance 9 km approx.</li></ul>",
    htmlTwo: "<h5 class='borderBox'>INCLUDED SERVICES</h5><ul><li>Private hiking guide</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/nature-adventure/punta-campanella-monte-sancostanzo",
    isActiveCarousel: false,
  },
  {
    id: 9,
    category: "NatureAdventure",
    categoryInternal: "BayOfJeranto",
    imgCardParent: require('../content/img/natureAdventure/bay-of-Jeranto/bay-of-Jeranto-2.jpg'),
    serviceName: "Bay Of Jeranto",
    description: "Discover the enchanted Bay of Jeranto – part of the Marine Protected Area of Punta della Campanella - and take a swim into the crystalline waters of this nature preserve with panoramic views of Capri Island and the Faraglioni. During an exclusive excursion with a professional hiking guide, you’ll venture into awe-inspiring panoramic views of the coastline, the biodiverse natural scenery and the century-old olive and citrus groves tracing the path to the sea.",
    shortDescription: "Discover the enchanted Bay of Jeranto – part of the Marine Protected Area of Punta della...",
    serviceNameParent: "Nature & Adventure",
    linkParentCategory: "/nature-adventure#return",
    linkImgOne: require('../content/img/natureAdventure/bay-of-Jeranto/bay-of-Jeranto-2.jpg'),
    linkImgTwo: require('../content/img/natureAdventure/bay-of-Jeranto/bay-of-Jeranto-3.jpg'),
    linkImgThree: require('../content/img/natureAdventure/bay-of-Jeranto/bay-of-Jeranto-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "3 - 4 HOURS",
    price: "FROM €230,00",
    htmlOne: "<h5 class='borderBox'>HIGHLIGHT</h5><ul><li>Nerano fishermen village, Montalto Tower, Jeranto Bay</li><li>Distance 5 km approx.</li></ul>",
    htmlTwo: "<h5 class='borderBox'>INCLUDED SERVICES</h5><ul><li>Private hiking guide</li><li>Private Transfer to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/nature-adventure/bay-of-jeranto",
    isActiveCarousel: false,
  },
  {
    id: 10,
    category: "Cultural Activities",
    categoryInternal: "PompeiVesuvius",
    imgCardParent: require('../content/img/culturalActivities/pompei-herculaneum/pompei-herculaneum-2.jpg'),
    serviceName: "Pompei & Vesuvius",
    description: "Immerse yourself in art and history and rediscover the UNESCO World Heritage Site of Pompei, the flourishing ancient Roman cities buried by the eruption of Mount Vesuvius in 79 A.D.Stroll through the well-preserved Roman cobblestone streets and admire the wonders of this open-air museum - the colorful frescoes, mosaics and unique architectural and anthropological treasures provide a window into the ancient world. The journey will take you from the historical wonders to the natural scenery of Mount Vesuvius with its enchanting vineyards where you can enjoy an exquisite wine tasting experience.",
    shortDescription: "Immerse yourself in art and history and rediscover the UNESCO World Heritage Sites of Pompei...",
    serviceNameParent: "Cultural Activities",
    linkParentCategory: "/cultural-activities#return",
    linkImgOne: require('../content/img/culturalActivities/pompei-herculaneum/pompei-herculaneum-2.jpg'),
    linkImgTwo: require('../content/img/culturalActivities/pompei-herculaneum/pompei-herculaneum-3.jpg'),
    linkImgThree: require('../content/img/culturalActivities/pompei-herculaneum/pompei-herculaneum-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "8 HOURS",
    price: "FROM €550,00",
    htmlOne: "<h5 class='borderBox'>HIGHLIGHT</h5><ul><li>Pompei – UNESCO World Heritage Sites</li><li>Optional culinary experience and wine tasting</li><li>A professional guide can be requested during the tour of Pompei for an additional fee (155 euros)</li><li>Entrance tickets to the cultural sites are not included</li><li>A valid ID is required</li></ul>",
    htmlTwo: "<h5 class='borderBox'>INCLUDED SERVICES</h5><ul><li>Private driver to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "cultural-activities/pompei-vesuvius",
    isActiveCarousel: false,
  },
  {
    id: 11,
    category: "Cultural Activities",
    categoryInternal: "Vesuvius",
    imgCardParent: require('../content/img/culturalActivities/vesuvius/vesuvius-2.jpg'),
    serviceName: "Pompei or Vesuvius Getaway",
    description: "Traverse the winding roads of the Vesuvius National Park in the comfort of your private luxury van, blend into the natural landscapes and contemplate the magnificent scenery of the volcano all the way to the crater, 1000 meters above the sea level with immaculate views of the Gulf of Naples and the islands. Stop at a stunning vineyard on the slopes of Mount Vesuvius where Lacryma Christi is produced and enjoy a wine tasting paired with a unique culinary experience. The journey will take you from the historical wonders of Pompei where you will admire the colorful frescoes, mosaics and unique architectural and anthropological treasures.",
    shortDescription: "Traverse the winding roads of the Vesuvius National Park in the comfort of your private luxury van, blend into...",
    serviceNameParent: "Cultural Activities",
    linkParentCategory: "/cultural-activities#return",
    linkImgOne: require('../content/img/culturalActivities/vesuvius/vesuvius-2.jpg'),
    linkImgTwo: require('../content/img/culturalActivities/vesuvius/vesuvius-3.jpg'),
    linkImgThree: require('../content/img/culturalActivities/vesuvius/vesuvius-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "8 HOURS",
    price: "FROM €490,00",
    htmlOne: "<h5 class='borderBox'>HIGHLIGHT</h5><ul><li>1/2 hours hike to the crater of the volcano with a professional guide</li><li>Pompei – UNESCO World Heritage Site</li><li>Optional culinary experience and wine tasting</li><li>A professional guide can be requested during the tour of Pompei and Mount Vesuvius for an additional fee</li><li> Entrance tickets to the Vesuvius National Park and cultural site are not included</li><li>A valid ID is required</li></ul>",
    htmlTwo: "<h5 class='borderBox'>INCLUDED SERVICES</h5><ul><li>Private driver to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "cultural-activities/vesuvius",
    isActiveCarousel: false,
  },
  {
    id: 12,
    category: "Cultural Activities",
    categoryInternal: "ScenicRoute",
    imgCardParent: require('../content/img/culturalActivities/scenic-route/scenic-route-2.jpg'),
    serviceName: "The Amalfi coast scenic route",
    description: "Take in some of the most breathtaking views and explore the Mediterranean coastline as you wind through the lemon and olive groves and the picturesque towns of the Amalfi Coast by private luxury van. Our Concierge has selected the main cultural and natural highlights along beautiful scenic roads between Positano and Ravello. Your journey starts in Positano, the jet-set resort destination with the most elegant boutiques, cafes and art galleries. Continue to Praiano, the old fishing village with whitewashed homes and terraced gardens boasting the best views over the Amalfi Coast and Capri. Stop by the famous Church of San Gennaro to admire its unique blue and yellow majolica dome before arriving at the charming fjord of Furore and Conca Dei Marini. Only a ten-minute drive from there is Amalfi and its Cathedral of St. Andrew in the Piazza del Duomo. Follow your way to Ravello and admire Villa Rufolo and Villa Cimbrone magnificent gardens with breathtaking sea views.",
    shortDescription: "Take in some of the most breathtaking views and explore the Mediterranean coastline as you wind through the...",
    serviceNameParent: "Cultural Activities",
    linkParentCategory: "/cultural-activities#return",
    linkImgOne: require('../content/img/culturalActivities/scenic-route/scenic-route-2.jpg'),
    linkImgTwo: require('../content/img/culturalActivities/scenic-route/scenic-route-3.jpg'),
    linkImgThree: require('../content/img/culturalActivities/scenic-route/scenic-route-1.jpg'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "8 HOURS",
    price: "FROM €550,00",
    htmlOne: "<h5 class='borderBox'>HIGHLIGHT</h5><ul><li>Positano, Praiano, Furore Fjord, Conca Dei Marini, Ravello</li><li>Culinary experience upon request</li></ul>",
    htmlTwo: "<h5 class='borderBox'>INCLUDED SERVICES</h5><ul><li>Private driver to/from your property</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "cultural-activities/scenic-route",
    isActiveCarousel: false,
  },
  {
    id: 14,
    category: "NatureAdventure",
    categoryInternal: "Freediving",
    imgCardParent: require('../content/img/natureAdventure/freediving-course/freediving-course-1.png'),
    serviceName: "Freediving Course",
    description: "Immerse yourself in the art of freediving with Antonio Mironi, a certified instructor recognized by the Apnea Academy Sports Association and the Italian Foundation for Sport Fishing and Underwater Activities (FIPSAS). As a seasoned expert and connoisseur of the mesmerizing Capri seabed, Sorrento Peninsula, and Amalfi Coast nature preserve, Antonio invites you to discover the purest form of underwater exploration. <br>  Antonio's expert guidance will focus on the fundamentals of freediving and safety, leading participants through techniques such as relaxation, finning, controlled breathing, equalization, and more - integral aspects of the captivating sport of freediving.<br> Extend your journey with Antonio to a 2-day freediving immersion, allowing you to deepen your connection with the Mediterranean, engage with marine life, and gain insights from Antonios extensive freediving experience. This adventure includes the delivery of a professional diving certificate from the Apnea Academy.",
    shortDescription: "Immerse yourself in the art of freediving with Antonio Mironi... ",
    serviceNameParent: "Nature & Adventure",
    linkParentCategory: "/nature-adventure#return",
    linkImgOne: require('../content/img/natureAdventure/freediving-course/freediving-course-1.png'),
    linkImgTwo: require('../content/img/natureAdventure/freediving-course/freediving-course-2.png'),
    linkImgThree: require('../content/img/natureAdventure/freediving-course/freediving-course-3.png'),
    TitleLeft1: "Duration",
    TitleLeft2: "Price",
    duration: "4 HOURS",
    price: "FROM €90,00",
    htmlOne: "<h5 class='borderBox'>HIGHLIGHT</h5><ul><li>Jeranto Bay, Capri, Amalfi Coast </li></ul>",
    htmlTwo: "<h5 class='borderBox'>INCLUDED SERVICES</h5><ul><li>Diving equipment</li><li>Round-trip transportation by boat from Massa Lubrense Harbor to diving locations</li><li>English-speaking certified dive instructor</li></ul>",
    htmlThree: "",
    buttonText: "View Experience",
    linkToPage: "/nature-adventure/freediving",
    isActiveCarousel: false,
  },
  //TODO
  {
    id: 15,
    category: "CountryLifestyle",
    categoryInternal: "MozzarellaMaking",
    serviceName: "Unveiling the art of Mozzarella Making ",
    imgCardParent: require('../content/img/culinaryExperiences/mozzarella-making-2.jpg'),
    description: "Immerse yourself in the heart of Italy's culinary tradition with our mozzarella making experience. Delve into the rich heritage of Italian cheese craftsmanship guided by our expert private chef. Witness the time-honored techniques of curd kneading and stretching as you engage in this hands-on demonstration. Take in the aroma of freshly crafted mozzarella and savor its exquisite taste during a delightful tasting session. Our immersive experience invites you to connect with the essence of authentic Italian gastronomy, creating cherished moments and a deeper appreciation for this revered art form. Our concierge would be pleased to arrange a private transfer service from your villa to the venue upon request.",
    shortDescription: "Immerse yourself in the heart of Italy's culinary tradition with our mozzarella making experience. Delve into the rich heritage of Italian cheese...",
    serviceNameParent: "Country Lifestyle",
    linkParentCategory: "/culinary-experiences/country-lifestyle#return",
    linkImgOne: require('../content/img/culinaryExperiences/mozzarella-making-3.jpg'),
    linkImgTwo: require('../content/img/culinaryExperiences/mozzarella-making-2.jpg'),
    linkImgThree: require('../content/img/culinaryExperiences/mozzarella-making-1.jpg'),
    TitleLeft1: "Minimum",
    TitleLeft2: "Price",
    duration: "2 persons",
    price: "FROM €55,00",
    buttonText: "View Experience",
    linkToPage: "/culinary-experiences/country-lifestyle/mozzarella-making",
    isActiveCarousel: false,
  },
  {
    id: 16,
    category: "CountryLifestyle",
    categoryInternal: "Mozzarella&Limoncello",
    serviceName: "Crafting limoncello liquor",
    imgCardParent: require('../content/img/culinaryExperiences/crafting-mozzarella-limoncello-liquor-02.jpg'),
    description: "Delight in the fresh, citrusy aroma, and savor the exquisite taste of your own handcrafted Limoncello. Our immersive experience invites you to forge a deeper connection with Italian culture while creating cherished memories around this revered beverage. Immerse yourself in the essence of Italy's vibrant flavors with an exclusive Limoncello-making experience. Dive into the traditions of handcrafting this iconic Italian liquor, guided by our skilled artisans. Witness the meticulous process of infusing the zesty flavors of Amalfi lemons into each batch and learn the secret techniques passed down through generations. This experience can only be reserved in combination with the mozzarella workshop. Our concierge would be pleased to arrange a private transfer service from your villa to the venue upon request.",
    shortDescription: "Delight in the fresh, citrusy aroma, and savor the exquisite taste of your own handcrafted Limoncello. Our immersive experience invites you to forge a deeper...",
    serviceNameParent: "Country Lifestyle",
    linkParentCategory: "/culinary-experiences/country-lifestyle#return",
    linkImgOne: require('../content/img/culinaryExperiences/crafting-mozzarella-limoncello-liquor-02.jpg'),
    linkImgTwo: require('../content/img/culinaryExperiences/crafting-mozzarella-limoncello-liquor-01.jpg'),
   linkImgThree: require('../content/img/culinaryExperiences/crafting-mozzarella-limoncello-liquor-03.jpg'),
    TitleLeft1: "Minimum",
    TitleLeft2: "Price",
    duration: "2 persons",
    price: "FROM €70,00",
    buttonText: "View Experience",
    linkToPage: "/culinary-experiences/country-lifestyle/crafting-mozzarella-limoncello-liquor",
    isActiveCarousel: false,
  },
  {
    id: 17,
    category: "CountryLifestyle",
    categoryInternal: "AlFrescoPicnic",
    serviceName: "AL FRESCO PICNIC EXPERIENCE",
    imgCardParent: require('../content/img/culinaryExperiences/picnic-al-fresco-03.jpg'),
    description: "Embark on a culinary journey that celebrates nature's bounty with an exquisite Picnic experience. Nestled amidst the breathtaking views of the Amalfi Coast and the Mediterranean Sea, our vegetable garden serves as the backdrop for this gastronomic adventure. Delight in a curated selection of seasonal produce harvested fresh from our gardens, expertly crafted into a gourmet feast by our talented chef. Indulge in an al fresco dining setting that harmonizes the beauty of nature with the artistry of Italian cuisine. Savor each bite as you bask in the tranquil ambiance, creating cherished moments with your loved ones amidst the captivating landscapes. Our concierge would be pleased to arrange a private transfer service from your villa to the venue upon request.",
    shortDescription: "Embark on a culinary journey that celebrates nature's bounty with an exquisite Picnic experience.Nestled amidst the breathtaking views of the Amalfi Coast...",
    serviceNameParent: "Country Lifestyle",
    linkParentCategory: "/culinary-experiences/country-lifestyle#return",
    linkImgOne: require('../content/img/culinaryExperiences/picnic-al-fresco-01.jpg'),
    linkImgTwo: require('../content/img/culinaryExperiences/picnic-al-fresco-03.jpg'),
    linkImgThree: require('../content/img/culinaryExperiences/picnic-al-fresco-02.jpg'),
    TitleLeft1: "Minimum",
    TitleLeft2: "Price",
    duration: "2 persons",
    price: "FROM €110,00",
    buttonText: "View Experience",
    linkToPage: "/culinary-experiences/country-lifestyle/al-fresco-picnic",
    isActiveCarousel: false,
  },




];


export default ExperiencesInternal;