//Inside this const we have the single experience (all information: inside and external carousel)
const internalTemplateBox = [
  {
    id: "wedding-events",
    category: "MemorableExperiences",
    imgSection: require('../content/img/memorableExperiences/wedding-events.jpg'),
    title: "WEDDINGS & EVENTS",
    description: "With our collection of properties around the Amalfi Coast, Feeling Italy offers one- of- a kind indoor and outdoor venues for an intimate wedding celebration, reception or special event. Our dedicated event planning specialists will assist you and your wedding consultant to create a memorable Italian celebration that exceeds every expectation.",
    orientation: "text-right"
  },
  {
    id: "yoga-retreats",
    category: "MemorableExperiences",
    imgSection: require('../content/img/memorableExperiences/yoga-retreats.jpg'),
    title: "YOGA AND MEDITATION RETREATS",
    description: "Our concierge is dedicated to create extraordinary experiences and lifetime memories by planning holistic escapes tailor-made to travelers unique and individual needs. Embark on a journey of well-being at one of our sensational Mediterranean villas. For a fully immersive experience of the senses, we offer yoga and meditation retreats designed to strengthen your connection with body, mind and soul.",
    orientation: "text-left"
  },
  {
    id: "car-vespa-services",
    category: "MemorableExperiences",
    imgSection: require('../content/img/memorableExperiences/car-vespa-services.jpg'),
    title: "CAR AND SCOOTER SERVICES",
    description: "Feeling Italy Concierge can assist guests in finding the perfect vehicle for self-drive tailored to their needs and desires. In partnership with luxury car services, our concierge offers a fleet of outstanding vehicles, including classic cars, vintage convertibles and sports cars. If you wish to explore the Amalfi Coast natural wonders at your own pace, we can arrange Vespa or scooter rentals as well.",
    orientation: "text-right"
  }
];


export default internalTemplateBox;