import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import MainPageCategory from "../utilities/MainPageCategory";
import CTA from "./CTA";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ExperiencesInternal from '../utilities/ExperiencesInternal';
import CardInternal from "./CardInternal";
import Footer from "./Footer";
import Header from "./Header";
import HeadingSection from "./HeadingSection";
import Menu from "./Menu";
import '../content/css/site.css'

const mobile = window.matchMedia("screen and (max-width: 767px)").matches;

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1
    }
};
const CustomRightArrow = ({ onClick }) => {
    return (
        <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={onClick}>
            <FontAwesomeIcon icon={faArrowRight} />
        </button>
    )
}
class TemplateStandard extends React.Component {

    render() {


        //change CountryLifestyle template
        const { category } = this.props;
        const isCountryLifestylePage = category === "CountryLifestyle";


        return (
            <>
                <Header bg={"Transparent"} />
                <HeadingSection position={this.props.position} />
                <Menu itemActive={this.props.active} backg={"White"} />
                {MainPageCategory.map((item, index) => {
                    return (
                        item.category === this.props.category ? (
                            <React.Fragment key={item.id}>
                                <section>
                                    {!isCountryLifestylePage && (
                                        <div className="my-5">
                                            <div className="container">
                                                <div className={`row reverseMobile`}>
                                                    <div className={`col-md-7 col-12`}>
                                                        <img alt="" className='imageTemplate1' src={item.urlImageSection} />
                                                    </div>
                                                    <div className={`col-md-5 col-12 my-auto ps-5 p-custMobile`}>
                                                        <h4 className='text-uppercase mb-5 title'>{item.titleIntro}</h4>
                                                        <p className='mb-3 p' dangerouslySetInnerHTML={{ __html: item.introduction }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    <div className="my-5">
                                        {!isCountryLifestylePage && (
                                            <div className="container-fluid">
                                                <div className="row my-5">
                                                    <div className="col-md-12 col-12">
                                                        <h4 className='title mt5rem mb-5 text-center'>{item.titleCarousel}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(isCountryLifestylePage && !mobile) && (
                                            <div className="mt10rem"></div>

                                        )}
                                        <div id="return">
                                            {item.carouselActive ? (
                                                <div className="container-fluid">
                                                    <div className="position-relative">
                                                        <div className="boxSliderHide"></div>
                                                        <Carousel
                                                            swipeable={mobile ? true : false}
                                                            arrows={true}
                                                            ssr
                                                            draggable={false}
                                                            slidesToSlide={1}
                                                            centerMode={true}
                                                            showDots={false}
                                                            responsive={responsive}
                                                            infinite={true}
                                                            // autoPlay={this.props.deviceType !== "mobile" ? true : false} 
                                                            autoPlay={false}
                                                            keyBoardControl={true}
                                                            transitionDuration={100}
                                                            containerClass="carousel-container carouselInternal"
                                                            dotListClass="custom-dot-list-style"
                                                            customRightArrow={<CustomRightArrow />}
                                                            itemClass="carousel-item-padding-40-px">
                                                            {ExperiencesInternal.map((item, index) => {
                                                                if (item.category === this.props.category) {
                                                                    return (
                                                                        <React.Fragment key={item.id}>
                                                                            <CardInternal key={"cardInternal" + item.id} urlImg={item.imgCardParent} serviceName={item.serviceName} shortDescription={item.shortDescription} titleButton={item.buttonText} linkToPage={item.linkToPage} />
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                                else { return null }
                                                            })}
                                                        </Carousel>
                                                    </div>
                                                </div>
                                            )
                                                :
                                                <div className="container">
                                                    <div className="row carouselInternal justify-content-center">
                                                        {ExperiencesInternal.map((item, index) => {
                                                            if (item.category === this.props.category) {
                                                                return (
                                                                    <React.Fragment key={item.id}>
                                                                        <div className="col-md-4 col-12">
                                                                            <CardInternal key={index} urlImg={item.imgCardParent} serviceName={item.serviceName} shortDescription={item.shortDescription} titleButton={item.buttonText} linkToPage={item.linkToPage} />
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                            else { return (null) }
                                                        })}

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <CTA></CTA>
                                </section>
                            </React.Fragment >
                        )
                            :
                            null

                    );
                })
                }
                <Footer />
            </>
        )
    }
}

export default TemplateStandard;