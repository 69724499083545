import React from "react";
import AccordionPageCategory from "../utilities/AccordionPageCategory";
import Accordion from 'react-bootstrap/Accordion'
import MainPageCategory from "../utilities/MainPageCategory";
import CTA from "./CTA";
import Header from "./Header";
import HeadingSection from "./HeadingSection";
import Footer from "./Footer";
import Menu from "./Menu";

class TemplateAccordion extends React.Component {

    render() {
        return (
            <>
            <Header bg={"Transparent"} />
            <HeadingSection position={this.props.position} />
            <Menu backg={"White"} itemActive={this.props.active} />
                <div className="container">
                    <div className="row justify-content-center mt-5 mb-5 privateTransfersP">
                        <div className="col-md-7 col-12 text-center ">
                            {MainPageCategory.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        {
                                            item.category === this.props.category ? (
                                                <p className="paragraph">{item.introduction}</p>)
                                                :
                                                null
                                        }
                                   </React.Fragment>
                                );
                            })
                            }
                        </div>
                    </div>
                    <div className="row justify-content-center" id="accordionConcierge">
                        <div className="col-lg-9 col-md-12 col-12">
                            <Accordion>
                                {AccordionPageCategory.map((item, index) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                        <Accordion.Item eventKey={item.id}>
                                            <Accordion.Header>{item.titleAccordion}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>{item.contentAccordion}</p>
                                                <p className="text-end">{item.price}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        </React.Fragment>
                                    );
                                })
                                }
                            </Accordion>
                        </div>
                    </div>
                </div>
                <CTA></CTA>
                <Footer />
            </>
        )
    }
}

export default TemplateAccordion;