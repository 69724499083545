import * as React from 'react';
import '../content/css/site.css';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CarouselHome from '../components/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import luxuryVacationRental from '../content/img/homepage/luxury-vacation-rental.jpg';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Video from '../components/Video';
import Menu from '../components/Menu';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>

      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


function Home() {

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  return (
    <>
      <Header bg={"Transparent"} />
      <Video />
      <Menu backg={"black"} />
      {/* Carousel experiences */}

      <div className="text-center p-0  bg-black" id="carouselHome">
      <h4 className='title pt-5 text-center text-white mb-4'>Featured experiences</h4>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          aria-label="tab"
          className='lineTabList subTitle p-0 pt-2'
        >

          <Tab label="Culinary Experiences" {...a11yProps(0)} />
          <Tab label="Cruise" {...a11yProps(1)} />
          <Tab label="Cultural activities" {...a11yProps(2)} />
        </Tabs>
        <div index={value} onChange={handleChangeIndex} >
          <TabPanel className="effectSlowly" value={value} index={0}>
            <CarouselHome key={"CulinaryExperiences"} category="CulinaryExperiences" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CarouselHome key={"Cruise"} category="Cruise" />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CarouselHome key={"CulturalActivities"} category="Cultural Activities" />
          </TabPanel>
        </div>
      </div>
      {/* <Tabs defaultIndex={1} className="bg-black" id="carouselHome">
        <h4 className='title pt-5 text-center text-white mb-3'>Featured experiences</h4>
        <TabList className="text-center p-0 pt-2 lineTabList subTitle tabMobile">
          <Tab>Culinary Experiences</Tab>
          <Tab>Cruise</Tab>
          <Tab>Cultural activities</Tab>
        </TabList>
        <TabPanel>
          <CarouselHome key={"CulinaryExperiences"} category="CulinaryExperiences" />
        </TabPanel>
        <TabPanel>
          <CarouselHome key={"Cruise"} category="Cruise" />
        </TabPanel>
        <TabPanel>
          <CarouselHome key={"CulturalActivities"} category="Cultural Activities" />
        </TabPanel>
      </Tabs> */}
      <div className='position-relative zIndex1'>
        {/* Feeling Italy introduction */}
        <section className='bg-white introductionFeelingItaly'>
          <div className='container py-5 p0-xs'>
            <div className='row'>
              <div className='offset-lg-1 col-lg-4 col-12 my-auto ps-3 p-xs luxuryRental'>
                <h4 className='title mb-5'>Luxury Vacation Rental</h4>
                <p className='mb-5 paragraph'>Feeling Italy is a property management company specializing in luxury Mediterranean villas and lifestyle experiences in the Amalfi Coast.<br /> All of our villas are managed exclusively by our Villa Specialists with in-depth knowledge of the destination to suit travelers’ profiles and interests.<br /><br /> Immerse in the local culture and explore our collection of lifestyle experiences. </p>
                <a className='link effectArrow' href="/contact">Contact us <FontAwesomeIcon className="ms-2 effectArrow" icon={faArrowRight} /></a>
              </div>
              <div className='col-lg-7 col-12 villaImage'>
                <img className='villaAragona' src={luxuryVacationRental} alt='' />
              </div>
            </div>
          </div>
        </section>

        {/* Concierge Introduction */}
        <section className='bg-black introductionConcierge'>
          <div className='container py-5 bg-black p-xs'>
            <div className='row justify-content-center'>
              <div className='col-md-8 col-12 my-auto ps-3 text-left text-white'>
                <h4 className='mb-5 text-center title'>FEELING ITALY CONCIERGE</h4>
                <p className='mb-3 '>
                  Feeling Italy Concierge is an exclusive service tailored to travelers’ profiles, desires and unique and individual needs. Our leisure and lifestyle experiences are designed by A-List travel advisors with extensive knowledge of the territory and high-quality services, to meet at best the expectations of our clientele. <br /><br />
                  Our A-List travel advisors are available before and during your vacation, offering personalized one-to-one consultancy services, for a flawless customer experience. Exclusive villas and boutique homes along the Amalfi Coast, luxury cruises of the Mediterranean islands, gastronomic tastings in private olive and citrus groves and adventure travel activities are just a glance of our collection of lifestyle experiences.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default Home;
