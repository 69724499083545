import React from "react";
import SliderPageCategory from "../utilities/SliderPageCategory";
import Accordion from 'react-bootstrap/Accordion';
import Footer from "./Footer";
import HeadingSection from "./HeadingSection";
import Menu from "./Menu";
import Header from "./Header";
import { ParallaxProvider } from "react-scroll-parallax";
import AdvancedBanner from "./AdvancedBanner";


function hideModal(index) {
    var modal = document.getElementById("modal" + index);
    document.body.style.overflow = 'auto';
    modal.classList.remove('show');
};
const mobile = window.matchMedia("screen and (max-width: 767px)").matches;

class TemplateSlider extends React.Component {

    render() {
        return (
            <>
                <Header bg={"Transparent"} />
                <HeadingSection position={this.props.position} />
                <Menu backg={"White"} itemActive={this.props.active} />



                <ParallaxProvider>
                    {SliderPageCategory.map((item, index) => {
                        return (
                            item.category === this.props.category &&
                            <React.Fragment key={item.id}>
                                <AdvancedBanner item={item} mobile={mobile} />

                                {item.isModal &&
                                    <div id={"modal" + item.id} key={item.id} className="modalDetail modal fade">
                                        <div className="modal-dialog">
                                            <div className="modal-content paddingCustomModalContent">
                                                <div className="modal-header paddingCustomModal">
                                                    <button type="button" className="close lineEffectBlack" onClick={() => hideModal(item.id)}>
                                                        {/* <span className="iconClose"><FontAwesomeIcon icon={faClose} /></span> */}
                                                        <span>CLOSE</span>
                                                    </button>
                                                </div>
                                                <Accordion>
                                                    {item.detail.map((itemDetail, index) => {
                                                        return (
                                                            <React.Fragment key={itemDetail.id}>
                                                                <Accordion.Item eventKey={itemDetail.id}>
                                                                    <Accordion.Header>{itemDetail.title}</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <p>{itemDetail.description}</p>
                                                                        <p>{itemDetail.duration}<span className="float-end">{itemDetail.price}</span></p>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Accordion>
                                                <div className="container-fluid p-0">
                                                    <div className="text-center">
                                                        <a href="/reservation-form" className="lineEffectBlack text-black text-decoration-none">
                                                            BOOK YOUR EXPERIENCE
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>


                        )
                    }

                    )}
                </ParallaxProvider>



                <Footer />
            </>
        )
    }
}

export default TemplateSlider;