import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import internalTemplateBox from "../utilities/internalTemplateBox";
import HeadingSection from "./HeadingSection";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";

class TemplateBox extends React.Component {

    render() {
        return (
            <>
                <Header bg={"Transparent"} />
                <HeadingSection position={this.props.position} />
                <Menu backg={"White"} />
                <div className='container-fluid templateBox'>
                    {internalTemplateBox.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <section id={item.id}>
                                    {this.props.category === item.category ?
                                        item.orientation === "text-right" ?
                                            <div className="row">
                                                <div className="col-md-6 col-12 p-0">
                                                    <img className="w-100" src={item.imgSection} alt={item.title} />
                                                </div>
                                                <div className="col-md-6 col-12 bg-black text-white d-table vh-50Custom">
                                                    <div className="paddingBox h-100 align-middle d-table-cell">
                                                        <h4 className="title">{item.title}</h4>
                                                        <p className="mt-5 mb-0">{item.description}</p>
                                                        <div className="containerCtaTemplateBox">
                                                        <a href={"/reservation-form"} className="effectArrow text-white link m-auto mt-5">Book your experience <FontAwesomeIcon icon={faArrowRight} /></a>
                                                        </div>                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="row" id={item.id}>
                                                <div className="col-md-6 col-12 bg-black text-white d-table vh-50Custom">
                                                    <div className="paddingBox h-100 align-middle d-table-cell">
                                                    <h4 className="title">{item.title}</h4>
                                                        <p className="mt-5 mb-0">{item.description}</p>
                                                        <div className="containerCtaTemplateBox">
                                                        <a href={"/reservation-form"} className="effectArrow text-white link m-auto mt-5">Book your experience <FontAwesomeIcon icon={faArrowRight} /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 p-0" >
                                                    <img className="w-100" src={item.imgSection} alt={item.title} />
                                                </div>
                                            </div>

                                        : null
                                    }
                                </section>
                                </React.Fragment>
                        );
                    })}
                </div>
                <Footer />
            </>
        )
    }
}

export default TemplateBox;