import React from "react";
class ImgDetail extends React.Component {
    render() {
        return (
            <div className="p-0">
                <img alt={this.props.title} src={this.props.linkImg} />
            </div>
        )
    }
}

export default ImgDetail; 
