import React from "react";


class Link extends React.Component {
    render() {
        return (
            <a href={this.props.href} key={this.props.id} className={this.props.class} target={this.props.target}>
                {this.props.text}
            </a>
        )
    }
}

export default Link;