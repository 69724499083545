import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/content/css/site.css';
import './components/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home';
import ReservationForm from './pages/ReservationForm';
import Contact from './pages/Contact';
import TermsConditions from './pages/TermsConditions';
import NotFound from './pages/NotFound';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TemplateStandard from './components/TemplateStandard';
import TemplateCarouselInternal from './components/TemplateCarouselInternal';
import TemplateSlider from './components/TemplateSlider';
import TemplateAccordion from './components/TemplateAccordion';
import TemplateBox from './components/TemplateBox';
import PrivacyPolicy from './pages/PrivacyPolicy';
// import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>

        {/* Home page & general page */}
        <Route path="/" element={<Home />} />
        <Route path="/reservation-form" element={<ReservationForm />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />

        {/* Cruise category & sub category */}
        <Route path="/cruise" element={<TemplateStandard category={"Cruise"} active={"Cruise"} position={"Cruise"}></TemplateStandard>} />
        <Route path="/cruise/sunset-cruise" element={<TemplateCarouselInternal categoryInternal={"SunsetCruise"} category={"Cruise"} />} />
        <Route path="/cruise/amalfi-coast" element={<TemplateCarouselInternal categoryInternal={"AmalfiCoast"} category={"Cruise"} />} />
        <Route path="/cruise/capri-island" element={<TemplateCarouselInternal categoryInternal={"CapriIsland"} category={"Cruise"} />} />
        <Route path="/cruise/ischia-procida" element={<TemplateCarouselInternal categoryInternal={"IschiaProcida"} category={"Cruise"} />} />
        <Route path="/cruise/capri-positano" element={<TemplateCarouselInternal categoryInternal={"CapriPositano"} category={"Cruise"} />} />
        <Route path="/cruise/amalfi-coast-shared-cruise" element={<TemplateCarouselInternal categoryInternal={"AmalfiCoastShared"} category={"Cruise"} />} />
        <Route path="/cruise/capri-island-shared-cruise" element={<TemplateCarouselInternal categoryInternal={"CapriIslandShared"} category={"Cruise"} />} />
        <Route path="/cruise/dufour-390-sailing-yacht" element={<TemplateCarouselInternal categoryInternal={"Dufour390SailingYacht"} category={"Cruise"} />} />

        {/* Culinary Experiences */}
        <Route path="/culinary-experiences" element={<TemplateSlider category={"CulinaryExperiences"} active={"Culinary Experiences"} position={"CulinaryExperiences"}> </TemplateSlider>} />
        <Route path="/culinary-experiences/country-lifestyle" element={<TemplateStandard category={"CountryLifestyle"} active={"CountryLifestyle"} position={"CountryLifestyle"}></TemplateStandard>} />
        <Route path="/culinary-experiences/country-lifestyle/mozzarella-making" element={<TemplateCarouselInternal categoryInternal={"MozzarellaMaking"} category={"CountryLifestyle"} />} />
        <Route path="/culinary-experiences/country-lifestyle/crafting-mozzarella-limoncello-liquor" element={<TemplateCarouselInternal categoryInternal={"Mozzarella&Limoncello"} category={"CountryLifestyle"} />} />
        <Route path="/culinary-experiences/country-lifestyle/al-fresco-picnic" element={<TemplateCarouselInternal categoryInternal={"AlFrescoPicnic"} category={"CountryLifestyle"} />} />


        {/* In Villa*/}
        <Route path="/in-villa" element={<TemplateSlider category={"In-Villa"} active={"In-Villa"} position={"In-Villa"}> </TemplateSlider>} />

        {/* Nature & Adventure category & sub category */}
        <Route path="/nature-adventure" element={<TemplateStandard category={"NatureAdventure"} active={"Nature & Adventure"} position={"NatureAdventure"}></TemplateStandard>} />
        <Route path="/nature-adventure/path-of-the-gods" element={<TemplateCarouselInternal categoryInternal={"PathOfGods"} category={"NatureAdventure"} />} />
        <Route path="/nature-adventure/the-mills-valley" element={<TemplateCarouselInternal categoryInternal={"TheMillsValley"} category={"NatureAdventure"} />} />
        <Route path="/nature-adventure/punta-campanella-monte-sancostanzo" element={<TemplateCarouselInternal categoryInternal={"MarineHiking"} category={"NatureAdventure"} />} />
        <Route path="/nature-adventure/bay-of-jeranto" element={<TemplateCarouselInternal categoryInternal={"BayOfJeranto"} category={"NatureAdventure"} />} />
        <Route path="/nature-adventure/freediving" element={<TemplateCarouselInternal categoryInternal={"Freediving"} category={"NatureAdventure"} />} />

        {/* Cultural Activities category & sub category */}
        <Route path="/cultural-activities" element={<TemplateStandard category={"Cultural Activities"} active={"Cultural Activities"} position={"Cultural Activities"}></TemplateStandard>} />
        <Route path="/cultural-activities/pompei-vesuvius" element={<TemplateCarouselInternal categoryInternal={"PompeiVesuvius"} category={"Cultural Activities"} />} />
        <Route path="/cultural-activities/vesuvius" element={<TemplateCarouselInternal categoryInternal={"Vesuvius"} category={"Cultural Activities"} />} />
        <Route path="/cultural-activities/scenic-route" element={<TemplateCarouselInternal categoryInternal={"ScenicRoute"} category={"Cultural Activities"} />} />

        

        {/* Wellness */}
        <Route path="/wellness" element={<TemplateSlider category={"Wellness"} active={"Wellness"} position={"Wellness"}> </TemplateSlider>} />

        {/* Private Transfers */}
        <Route path="/private-transfers" element={<TemplateAccordion category={"PrivateTransfers"} active={"Private Transfers"} position={"PrivateTransfers"}> </TemplateAccordion>} />

        {/* Memorable Experiences category & sub category */}
        <Route path="/memorable-experiences" element={<TemplateBox category={"MemorableExperiences"} position={"MemorableExperiences"}></TemplateBox>} />
      
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// reportWebVitals();