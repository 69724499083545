import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import MenuLink from '../utilities/MenuLink';
import '../content/css/site.css';

// function useScrollDirection() {
//     const [scrollDirection, setScrollDirection] = React.useState(null);

//     React.useEffect(() => {
//         let lastScrollY = window.pageYOffset;

//         const updateScrollDirection = () => {
//             const scrollY = window.pageYOffset;
//             const direction = scrollY > lastScrollY ? "down" : "up";
//             if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
//                 setScrollDirection(direction);
//             }
//             lastScrollY = scrollY > 0 ? scrollY : 0;
//         };
//         window.addEventListener("scroll", updateScrollDirection); // add event listener
//         return () => {
//             window.removeEventListener("scroll", updateScrollDirection); // clean up
//         }
//     }, [scrollDirection]);

//     return scrollDirection;
// };

class Menu extends React.Component {
    state = {
        isScroll: "stay",
    };

    componentDidMount() {
        document.addEventListener('scrollend', () => {
            this.setState({
                isScroll: "upDown"
            })
        })
        document.addEventListener('mouseout', () => {
            this.setState({
                isScroll: "upDown"
            })
        })
        document.getElementById('menuNav').addEventListener('mouseout', () => {
            this.setState({
                isScroll: "upDown"
            })
        })
        document.addEventListener('scroll', () => {
            this.setState({
                isScroll: "stay"
            })
        })
        document.addEventListener('mouseover', () => {
            this.setState({
                isScroll: "stay"
            })
        }) 
        document.getElementById('menuNav').addEventListener('mouseover', () => {
            this.setState({
                isScroll: "stay"
            })
        }) 
    }
    render() {
        return (
            <Navbar id={"menuNav"} key={"menu"} className={`${this.state.isScroll === "upDown" ? "top-negative" : ""} transition-all duration-500 bgNav mobileNone`} bg={this.props.backg} expand="lg" >
                <div className="container-fluid">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-white" />
                    <Navbar.Collapse className="basic-navbar-nav">
                        <Nav className="m-auto">
                            {
                                MenuLink.map((item, index) => {
                                    return (
                                        <div className="text-center" key={item.id}>
                                            {item.position === "Header" && item.text === this.props.itemActive ?
                                                <Nav.Link href={item.href} key={"Menu" + index} className={"active " + item.class}>{item.text}</Nav.Link>
                                                :
                                                item.position === "Header" ?
                                                    <Nav.Link href={item.href} key={"Menu" + index} className={item.class}>{item.text}</Nav.Link>
                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar >
        )
    }
}

export default Menu;
