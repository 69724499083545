import React from "react";
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Accordion from "./Accordion";

var config = {
    cookieTypes: [
        // Cookie types, value and description (Cookie Preferences Selection)
        {
            id: 1,
            name: "Strictly Necessary Cookies",
            value: "necessary",
            description: "Necessary cookies are absolutely essential for the website to function properly. These cookies ensure basic functionalities and security features of the website, anonymously.",
            active: true
        },
        {
            id: 2,
            name: "Functional Cookies",
            value: "functional",
            description: "Functional cookies help to perform certain functionalities like sharing the content of the website on social media platforms, collect feedbacks, and other third-party features.",
            active: false
        },
        {
            id: 3,
            name: "Performance Cookies",
            value: "performance",
            description: "Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.",
            active: false
        },
        {
            id: 4,
            name: "Analytics Cookies",
            value: "analytics",
            description: "Analytics cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site.",
            active: false
        },
        {
            id: 5,
            name: "Marketing Cookies",
            value: "marketing",
            description: "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.",
            active: false
        },
        {
            id: 6,
            name: "Preferences Cookies",
            value: "preferences",
            description: "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.",
            active: false
        },
        {
            id: 7,
            name: "Others Cookies",
            value: "others",
            description: "Other uncategorized cookies are those that are being analyzed and have not been classified into a category as yet.",
            active: false
        },

    ],
    descriptionBanner: "Our website uses cookies to enhance your browsing experience. Of these, cookies classified as necessary are stored on your browser as they are essential for the basic functionality of the site to work. We also use third-party cookies that help us analyze and understand how users use the site. These cookies are stored in your browser only with your consent. You also have the option to opt out of these cookies. However, opting out of some of these cookies may affect your browsing experience.",
}

const cookies = new Cookies();
// let consent = cookies.get('acceptance') !== undefined ? true : false;

// eslint-disable-next-line
Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

class CookieBanner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalOpened: false,
            showBanner: true
        }
        this.acceptCookies = this.acceptCookies.bind(this)
        this.declineCookies = this.declineCookies.bind(this)
        this.acceptCookiesChoose = this.acceptCookiesChoose.bind(this)
        this.handleChange.bind(this);
    }

    componentDidMount() {
if (this.props.showBanner){
    console.log('this.props.showBanner:' + this.props.showBanner);
    this.setState({showBanner: true})
}
      }
    
    acceptCookies = () => {
        let date = new Date();
        cookies.set('acceptance', 'yes', { path: '/', expires: date.addDays(365) });

        //Insert all script to tracking
        // console.log(cookies.get('acceptance'));
        this.handleChange();
        
    }

        
    acceptCookiesChoose = () => {
        let date = new Date();
        cookies.set('acceptance', 'yes', { path: '/', expires: date.addDays(365) });

        //Insert just the cookies choose
        this.handleChange();
        
        
    }
    


    handleChange = () => {
        this.setState({showBanner:false, modalOpened: false});
        this.props.changeState(false);
     };


    declineCookies = () => {
        let date = new Date();
        cookies.set('acceptance', 'no', { path: '/', expires: date.addDays(365) });
        //let gettingStores =  cookies.browser.getAllCookieStores();
        // gettingStores.map((x) => {
        //console.log(x);
        //})
        //set cookie acceptance to no
        this.handleChange();
    }


    render() {
        return (

            <>
            {this.state.showBanner &&
                    <div id="cookieBanner" className="bannerCookie">
                        <div className="boxLeftBanner p-3">
                            <p className="paragraph">We use cookies on our website to enhance your browsing experience by remembering your preferences and recurring visits. By clicking "Accept All," you are giving your consent for the use of all cookies.</p>
                        </div>
                        <div className="boxRightBanner">
                            <button className="link text-white lineEffect btnCookieBanner" onClick={e => this.setState({modalOpened: true})}>Manage Cookie</button>
                            <button className="link text-white lineEffect btnCookieBanner" onClick = {(e) => this.acceptCookies()}>Accept All</button>
                        </div>
                    </div>
    }
                {this.state.modalOpened &&
                    <div id={"cookieManage"} className="show transition-all duration-500 modalDetail modal fade">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="row">
                                        <div className="col">
                                            <h2 className="text-black subTitle">Privacy Overview</h2>
                                        </div>
                                        <div className="col">
                                            <button type="button" className="close" onClick={e => this.setState({modalOpened: false})}>
                                                <span className="iconClose"><FontAwesomeIcon icon={faClose} /></span><span></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <p className="paragraph-11">{config.descriptionBanner}</p>
                                        <button className="btnCookie" onClick = {(e) => this.acceptCookies()}>Allow All</button>
                                        <h2 className="text-black subTitle-18 mt-5 mb-3">Manage Cookies</h2>
                                        <div className="accordion">
                                            {config.cookieTypes.map((cookie) => {
                                                return (
                                                    <Accordion key={cookie.id} cookie={cookie} />
                                                )
                                            })}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 dFlexEnd mt-5">
                                                <button className="btnCookie" onClick = {(e) => this.declineCookies()}>Reject All</button>
                                                <button className="btnCookie" onClick = {(e) => this.acceptCookiesChoose()}>Confirm My Choices</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </>
        )





    }


}

export default CookieBanner;