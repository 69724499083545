//Inside this const we have the single element inside the carousel in the home page
const SliderHome = [
  {
    id: 1,
    category: "Cruise",
    categoryInternal: "SunsetCruise",
    img: require('../content/img/cruise/sunset-cruise/sunset-cruise-2.jpg'),
    service: "Sunset Cruise",
    description: "Watch the sunset over the Mediterranean Sea during a two-hours cruise along the awe-inspiring natural landscapes...",
    button: "View Experience",
    link: "/cruise/sunset-cruise",
    isActiveCarousel: true,
    isAnchor: false
  },
  {
    id: 20,
    category: "Cruise",
    categoryInternal: "DUFOUR 390 SAILING YACHT",
    img: require('../content/img/cruise/dufour-390-sailing-yacht/dufour-390-sailing-yacht-1.jpg'),
    service: "DUFOUR 390 SAILING YACHT",
    description: "Embark on a sailing adventure with our luxurious cruise by sailing boat. Experience the breathtaking beauty of the...",
    button: "View Experience",
    link: "/cruise/dufour-390-sailing-yacht",
    isActiveCarousel: true,
    isAnchor: false
},
  {
    id: 2,
    category: "Cruise",
    categoryInternal: "CapriIsland",
    service: "Capri Island",
    img: require('../content/img/cruise/capri/capri-island-2.jpg'),
    description: "Sail to the island of Capri famous for its Faraglioni towering rocks and the coastline wild beauty aboard our...",
    button: "View Experience",
    link: "/cruise/capri-island",
    isActiveCarousel: true,
    isAnchor: false
  },
  {
    id: 3,
    category: "Cruise",
    categoryInternal: "AmalfiCoast",
    img: require('../content/img/cruise/amalfi-coast/amalfi-coast-1.jpg'),
    service: "Amalfi Coast",
    description: "Sail the Amalfi Coast in total comfort aboard our Sorrentine Gozzo or elegant yachts, as we head to the stunning...",
    button: "View Experience",
    link: "/cruise/amalfi-coast",
    isActiveCarousel: true,
    isAnchor: false
  },
  {
    id: 4,
    category: "Cruise",
    categoryInternal: "CapriPositano",
    img: require('../content/img/cruise/capri-positano/capri-positano-2.jpg'),
    service: "Capri Island & Positano",
    description: "Cruise the coastline of Capri aboard our Sorrentine Gozzo or elegant yachts, where you will swim in crystal waters...",
    button: "View Experience",
    link: "/cruise/capri-positano",
    isActiveCarousel: true,
    isAnchor: false
  },
  {
    id: 5,
    category: "Cruise",
    categoryInternal: "IschiaProcida",
    img: require('../content/img/cruise/ischia-procida/ischia-procida-2.jpg'),
    service: "Ischia & Procida",
    description: "Cruise to the island of Ischia, also known as the Green Island, for the stunning natural scenery, pristine beaches...",
    button: "View Experience",
    link: "/cruise/ischia-procida",
    isActiveCarousel: true,
    isAnchor: false
  },
  
  {
    id: 9,
    category: "Cruise",
    categoryInternal: "AmalfiCoastShared",
    img: require('../content/img/cruise/amalfi-coast-shared/amalfi-coast-shared-2.jpg'),
    service: "Amalfi Coast Shared Cruise",
    description: "Sail the Amalfi Coast in total comfort aboard our Sorrentine Gozzo, as we head to the stunning archipelago of...",
    button: "View Experience",
    link: "/cruise/amalfi-coast-shared-cruise",
    isActiveCarousel: true,
    isAnchor: false
  },
  {
    id: 10,
    category: "Cruise",
    categoryInternal: "CapriIslandShared",
    img: require('../content/img/cruise/capri-island-shared/capri-island-shared-2.jpg'),
    service: "Capri Island Shared Cruise",
    description: "Sail to the island of Capri famous for its Faraglioni towering rocks and the coastline wild beauty aboard...",
    button: "View Experience",
    link: "/cruise/capri-island-shared-cruise",
    isActiveCarousel: true,
    isAnchor: false
  },

  // Start Cultural Activities
  {
    id: 6,
    category: "Cultural Activities",
    categoryInternal: "ScenicRoute",
    img: require('../content/img/culturalActivities/scenic-route/scenic-route-2.jpg'),
    service: "The Amalfi coast scenic route",
    description: "Take in some of the most breathtaking views and explore the Mediterranean coastline as you wind through the lemon and...",
    button: "View Experience",
    link: "cultural-activities/scenic-route",
    isActiveCarousel: true,
    isAnchor: false
  },
  {
    id: 7,
    category: "Cultural Activities",
    categoryInternal: "PompeiVesuvius",
    img: require('../content/img/culturalActivities/pompei-herculaneum/pompei-herculaneum-2.jpg'),
    service: "Pompei & Vesuvius",
    description: "Immerse yourself in art and history and rediscover the UNESCO World Heritage Sites of Pompei...",
    button: "View Experience",
    link: "cultural-activities/pompei-vesuvius",
    isActiveCarousel: true,
    isAnchor: false
  },
  {
    id: 8,
    category: "Cultural Activities",
    categoryInternal: "Vesuvius",
    img: require('../content/img/culturalActivities/vesuvius/vesuvius-2.jpg'),
    service: "Pompei or Vesuvius Getaway",
    description: "Traverse the winding roads of the Vesuvius National Park in the comfort of your private luxury van, blend into the natural...",
    button: "View Experience",
    link: "cultural-activities/vesuvius",
    isActiveCarousel: true,
    isAnchor: false
  },
  {
    id: 13,
    category: "CulinaryExperiences",
    img: require('../content/img/culinaryExperiences/oil-olive-gourmet-tasting.jpg'),
    service: "OLIVE OIL TASTING",
    description: "Savour the aromas of Italian cuisine, connect directly with local farmers who will introduce you to the tradition of extra virgin olive...",
    button: "View Experience",
    link: "culinary-experiences#OilTasting",
    isActiveCarousel: true,
    isAnchor: true
  },
  {
    id: 18,
    category: "CulinaryExperiences",
    img: require('../content/img/culinaryExperiences/farm-to-table.jpg'),
    service: "ORTO IN TAVOLA COOKING CLASS",
    description: "Join Chef Mimmo De Gregorio for an extraordinary culinary experience and one-of-a kind private cooking class. Set...",
    button: "View Experience",
    link: "culinary-experiences#FarmToTable",
    isActiveCarousel: true,
    isAnchor: true
  },
  {
    id: 22,
    category: "CulinaryExperiences",
    categoryInternal: "InVillaPrivateChef",
    service: "In-Villa Private Chef",
    img: require('../content/img/inVilla/in-villa-private-chef.jpg'),
    description: "Savour a private dining experience with a personal Chef in the comfort of your villa with a curated Italian menu tailored to...",
    button: "View Experience",
    link: "/In-Villa#InVillaPrivateChef",
    isActiveCarousel: true,
    isAnchor: true,
  },
  {
    id: 21,
    category: "CulinaryExperiences",
    categoryInternal: "MozzarellaMaking",
    img: require('../content/img/culinaryExperiences/mozzarella-making-2.jpg'),
    service: "Country Lifestyle",
    description: "Indulge in an immersive country-style experience and connect with the essence of authentic Italian gastronomy. Our private...",
    button: "View Experience",
    link: "/culinary-experiences#CountryLifestyle",
    isActiveCarousel: true,
    isAnchor: true,
  },
  {
    id: 19,
    category: "CulinaryExperiences",
    img: require('../content/img/culinaryExperiences/wine-tasting.jpg'),
    service: "WINE TASTING",
    description: "Our Wine Cellar is an intimate space designed with stone and wood finishes and handcrafted majolica walls stacked...",
    button: "View Experience",
    link: "culinary-experiences#WineTasting",
    isActiveCarousel: true,
    isAnchor: true
  },
{
  id: 23,
  category: "CulinaryExperiences",
  categoryInternal: "InVillaGourmetDinner",
  img: require('../content/img/inVilla/in-villa-gourmet-dining.jpg'),
  service: "IN-VILLA GOURMET DINING",
  description: "In-Villa Gourmet Dining is a fine-dining experience within the comfort of your private villa. Immerse yourself in our...",
  button: "View Experience",
  link: "/In-Villa#InVillaGourmetDinner",
  isActiveCarousel: true,
  isAnchor: true,
},

];


export default SliderHome;