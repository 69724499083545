import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from "../components/Card";
import SliderHome from '../utilities/SliderHome';
import SliderHomeMobile from "../utilities/SliderHomeMobile";
 
const mobile = window.matchMedia("screen and (max-width: 767px)").matches;
const tablet = window.matchMedia("screen and (max-width: 1024px)").matches;


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
    }
};


const ButtonGroup = ({ size, next, previous, goToSlide, ...rest }) => {

    var lenghtSlider = SliderHome.filter((item) => item.category === size).length;
    var { carouselState: { currentSlide } } = rest;
    function nextSlide() {
        next();
        var btnRight = document.getElementById('btnRight');
        btnRight.disabled = true;
        var spanSlide = document.getElementById('spanSlide');
        var pagination = parseInt(document.getElementById('spanSlide').textContent);
        if (pagination < lenghtSlider && pagination >= 1) {
            spanSlide.textContent = pagination + 1;
            btnRight.disabled = false;
        }
        else {
            spanSlide.textContent = 1
            btnRight.disabled = false;
        }
    }
    function prevSlide() {
        previous();
        var btnLeft = document.getElementById('btnLeft');
        btnLeft.disabled = true;
        var spanSlide = document.getElementById('spanSlide');
        var pagination = parseInt(document.getElementById('spanSlide').textContent);
        if (pagination <= lenghtSlider && pagination > 1) {
            spanSlide.textContent = pagination - 1;
            btnLeft.disabled = false;
        }
        else {
            spanSlide.textContent = lenghtSlider
            btnLeft.disabled = false;
        }
    }
    return (
        <div className="carousel-button-group customArrow">
            <button id="btnLeft" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" onClick={() => prevSlide()}> </button>
            <span className="spanSlide">
                {
                    <span id="spanSlide">1</span>
                } of {
                    lenghtSlider
                }
            </span>
            <button id="btnRight" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={() => nextSlide()} > </button>
        </div>
    );
};
class CarouselHome extends React.Component {
    render() {
        return (
            <div className="container-fluid py-5 p-xs-0">
                <Carousel
                    swipeable={mobile || tablet ? true : false}
                    arrows={false}
                    ssr={false}
                    draggable={false}
                    slidesToSlide={1}
                    showDots={false} 
                    responsive={responsive}
                    centerMode={mobile || tablet ? true : true}
                    infinite={true}
                    minimumTouchDrag={5}
                    rewindWithAnimation={true}
                    // autoPlay={this.props.deviceType !== "mobile" ? true : false} 
                    autoPlay={false}
                    keyBoardControl={false}
                    customRightArrow={false}
                    deviceType={this.props.deviceType}
                    customLeftArrow={false}
                    transitionDuration={100}
                    // customTransition="transform 600ms ease-in-out"
                    customTransition="transform .5s linear"
                    containerClass="container-with-dots carouselHome"
                    customButtonGroup={<ButtonGroup size={this.props.category} />}
                    itemClass="image-item"
                >
                    {
                        mobile || tablet ?
                            SliderHomeMobile.filter((item) => item.category === this.props.category).map((item, index) => (
                                <div className="position-relative d-flex" key={item.id}>
                                    <div className="opacityCarousel">
                                    </div>
                                    <Card key={index} urlImg={item.img} title={item.service} description={item.description} titleButton={item.button} urlButton={item.link} isAnchor={item.isAnchor} />
                                </div>
                            )) :
                            SliderHome.filter((item) => item.category === this.props.category).map((item, index) => (
                                <div className="position-relative d-flex" key={item.id}>
                                    <div className="opacityCarousel">
                                    </div>
                                    <Card key={index} urlImg={item.img} title={item.service} description={item.description} titleButton={item.button} urlButton={item.link} isAnchor={item.isAnchor} />
                                </div>
                            ))

                    }
                </Carousel>

            </div>
        )
    }
}

export default CarouselHome;