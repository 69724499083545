import React from "react";
import VideoHome from '../content/video/FeelingItaly.mp4'
import concierge from '../content/img/concierge-w.png';
import SearchFilter from "./SearchFilter";


const mobile = window.matchMedia("screen and (max-width: 767px)").matches;
const tablet = window.matchMedia("screen and (max-width: 991px)").matches;

class Video extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     query: ""
  //   };
  // }

  render() {
    return (
      <>
        <div className="sectionVideoHome">
          <div className="overlay"></div>
          <div className="container-fluid p-80 h-100 ps-xs-10 tabletPosition">
            <div className="d-table small-screen center-col h-100 videoMobile">
              <div className="d-table-cell align-middle text-left xs-v-bottom">
                
                {
                  !mobile && !tablet &&
                  <>
                  <img className="logoConcierge position-relative" src={concierge} alt="Feeling Italy Concierge" />
                   <SearchFilter/>
                  </>

                }
              </div>
            </div>
          </div>
          <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
            <source src={VideoHome} type="video/mp4" />
            <source src={VideoHome} type="video/ogg" />
          </video>
        </div>
      </>
    );
  };
  ;
}

export default Video;