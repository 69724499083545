import '../content/css/site.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

function NotFound() {
    return (
        <>
            <Header bg={"white"} />
            <div className='bg-white pTop vh-70'>
                <div className='container'>
                    <div className='row mb-5'>
                        <div className='col-md-12 col-12'>
                            <h1 className="title mt-5 mb-3 text-center text-black">Page not Found</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <p className='text-center'>
                                The page you are looking for does not exist. <br />
                                Contact our Concierge service or go back to the home page to discover more experiences.
                            </p>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-12 col-12'>
                            <p className='text-center'>
                            <a className={"link lineEffectBlack text-black me-3"} href="/">All Experiences</a>

                            </p>
                        </div>
                    </div>
                </div>
            </div >

            <Footer />
        </>
    )
}

export default NotFound;
