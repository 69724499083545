import React from "react"; 
import Cards from 'react-bootstrap/Card';

class CardInternal extends React.Component {
    render() {
        return (
            <Cards>
                <Cards.Img variant="top" src={this.props.urlImg} />
                <Cards.Body>
                    <a className="btnCardInternal lineEffectBlack" href={this.props.linkToPage}>{this.props.serviceName} </a>
                    <Cards.Text>
                        {this.props.shortDescription}
                    </Cards.Text>
                </Cards.Body>
            </Cards>
        )
    }
}

export default CardInternal; 
