import React, { useState } from 'react';

const Accordion = ({ cookie }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <React.Fragment key={cookie.id}>

    <div className="accordion-item">
      <div
        className="accordion-title"
      >
        <div className="row">
          <div className="col-lg-8 col-xs-8 col-sm-8 col-xl-8 cookieTitleSx" onClick={() => setIsActive(!isActive)} >
            {isActive ? <span className="iconCookie">-</span> : <span className="iconCookie">+</span>} <span className="subTitle-13">{cookie.name}</span> 
          </div>
          <div className="col-lg-4 col-xs-4 col-sm-4 col-xl-4 dFlexEnd cookieTitleDx">
            <div className="form-check form-switch">

              {cookie.active ?
                <>
                  <label className="form-check-label paragraph-11 text-black" htmlFor="flexSwitchCheckCheckedDisabled">Always Active</label>
                  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedDisabled" checked disabled />
                </>
                :
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
              }

            </div>
          </div>
        </div>

      </div>
      {isActive && <div className="accordion-content"><p className="paragraph-11">{cookie.description}</p></div>}
    </div>
  
</React.Fragment>
  );
};

export default Accordion;