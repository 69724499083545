const MenuLink = [

  // HEADER

  {
    id:1,
    position: "Header",
    section: "",
    order: 0,
    text: "Cruise",
    href: "/cruise",
    show: true,
    class: "link-header lineEffect",
    target: "_self",
    anchor: false
  },
  {
    id:2,
    position: "Header",
    section: "",
    order: 1,
    text: "Culinary Experiences",
    href: "/culinary-experiences",
    show: true,
    class: "link-header lineEffect",
    target: "_self",
    anchor: false
  },
  {
    id: 20, 
    position: "Header",
    section: "",
    order: 7, 
    text: "In-Villa",
    href: "/in-villa", 
    show: true,
    class: "link-header lineEffect",
    target: "_self",
    anchor: false,
  },
  {
    id:3,
    position: "Header",
    section: "",
    order: 2,
    text: "Nature & Adventure",
    href: "/nature-adventure",
    show: true,
    class: "link-header lineEffect",
    target: "_self",
    anchor: false
  },
  {
    id:4,
    position: "Header",
    section: "",
    order: 3,
    text: "Cultural Activities",
    href: "/cultural-activities",
    show: true,
    class: "link-header lineEffect",
    target: "_self",
    anchor: false
  },
  // {
  //   id:5,
  //   position: "Header",
  //   section: "",
  //   order: 4,
  //   text: "Bespoke Services",
  //   href: "/bespoke-services",
  //   show: true,
  //   class: "link-header lineEffect",
  //   target: "_self",
  //   anchor: false
  // },
  {
    id:6,
    position: "Header",
    section: "",
    order: 5,
    text: "Wellness",
    href: "/wellness",
    show: true,
    class: "link-header lineEffect",
    target: "_self",
    anchor: false
  },
  {
    id:7,
    position: "Header",
    section: "",
    order: 6,
    text: "Private Transfers",
    href: "/private-transfers",
    show: true,
    class: "link-header lineEffect",
    target: "_self",
    anchor: false
  },
  
  ,

  // FOOTER
  
  {
    id:8,
    position: "Footer",
    section: "About",
    order: 0,
    text: "Contact",
    href: "/contact",
    show: true,
    class: "link-footer lineEffect",
    target: "_self",
    anchor: false
  },
  {
    id:9,
    position: "Footer",
    section: "About",
    order: 1,
    text: "Terms & Conditions",
    href: "/terms-conditions",
    show: true,
    class: "link-footer lineEffect",
    target: "_self",
    anchor: false
  },
  {
    id:10,
    position: "Footer",
    section: "About",
    order: 2,
    text: "Property Collection",
    href: "https://www.feelingitaly.com/",
    show: true,
    class: "link-footer lineEffect",
    target: "_blank",
    anchor: false
  },
  {
    id:11,
    position: "Footer",
    section: "Experiences",
    order: 0,
    text: "Weddings & Events",
    href: "/memorable-experiences#wedding-events",
    show: true,
    class: "link-footer lineEffect",
    target: "_self",
    anchor: true
  },
  {
    id:12,
    position: "Footer",
    section: "Experiences",
    order: 1,
    text: "Yoga Retreats",
    href: "/memorable-experiences#yoga-retreats",
    show: true,
    class: "link-footer lineEffect",
    target: "_self",
    anchor: true
  },
  {
    id:13,
    position: "Footer",
    section: "Experiences",
    order: 2,
    text: "Wellness",
    href: "/wellness",
    show: true,
    class: "link-footer lineEffect",
    target: "_self",
    anchor: false
  },
  // {
  //   id:14,
  //   position: "Footer",
  //   section: "Services",
  //   order: 0,
  //   text: "Bespoke Services",
  //   href: "/bespoke-services",
  //   show: true,
  //   class: "link-footer lineEffect",
  //   target: "_self",
  //   anchor: false
  // },
  {
    id:15,
    position: "Footer",
    section: "Services",
    order: 1,
    text: "Private Transfers",
    href: "/private-transfers",
    show: true,
    class: "link-footer lineEffect",
    target: "_self",
    anchor: false
  },
  {
    id:16,
    position: "Footer",
    section: "Services",
    order: 2,
    text: "Car & Vespa Services",
    href: "/memorable-experiences#car-vespa-services",
    show: true,
    class: "link-footer lineEffect",
    target: "_self",
    anchor: true
  },
  {
    id:17,
    position: "Footer",
    section: "Copyright",
    order: 0,
    text: "Privacy Policy",
    href: "/privacy-policy",
    show: true,
    class: "textCopyright lineEffect me-5",
    target: "_self",
    anchor: false
  },
  {
    id:18,
    position: "Footer",
    section: "Copyright",
    order: 1,
    text: "Cookie Preferences",
    href: "/cookie",
    show: true,
    class: "textCopyright lineEffect",
    target: "_self",
    anchor: false
  }
];

export default MenuLink;