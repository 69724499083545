const AccordionPageCategory = [
    {
        id: 1,
        category: "PrivateTransfers",
        titleAccordion: "FROM NAPLES TO YOUR VILLA – YOUR VILLA TO NAPLES",
        contentAccordion: "Our private transfer service with a certified driver is available from Naples-Capodichino International Airport, Napoli Centrale Train Station to Sorrento.",
        price: "PRICE FROM €190,00"
    },
    {
        id: 2,
        category: "PrivateTransfers",
        titleAccordion: "FROM ROME TO YOUR VILLA – YOUR VILLA TO ROME",
        contentAccordion: "Our private transfer service with a certified driver is available from Rome Fiumicino and Ciampino Airports, Rome Termini Train Station to Sorrento.",
        price: "PRICE FROM €650,00"
    }
]


export default AccordionPageCategory;