import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import 'react-multi-carousel/lib/styles.css';
import ExperiencesInternal from '../utilities/ExperiencesInternal';
import ImgDetail from "./ImgDetail";
import CTA from "./CTA";
import { HashLink as LinkHash } from 'react-router-hash-link';
import InfiniteCarousel from 'react-leaf-carousel';
import Header from "./Header";
import Footer from "./Footer";


// const responsive = {
//     superLargeDesktop: {
//         // the naming can be any, depends on you.
//         breakpoint: { max: 4000, min: 3000 },
//         items: 3
//     },
//     desktop: {
//         breakpoint: { max: 3000, min: 992 },
//         items: 3
//     },
//     tablet: {
//         breakpoint: { max: 991, min: 464 },
//         items: 2
//     },
//     mobile: {
//         breakpoint: { max: 464, min: 0 },
//         items: 1
//     }
// };
const superLargeDesktop = window.matchMedia("(min-width:3000px) and (max-width: 4000px)").matches;
const desktop = window.matchMedia("(min-width:1025px) and (max-width: 2999px)").matches;

const CustomRightArrow = ({ onClick }) => {
    return (
        <div className="arrowRightCarousel" onClick={onClick}>
            <FontAwesomeIcon onClick={onClick} icon={faArrowRight} />
        </div>
    )
}
const CustomLeftArrow = ({ onClick }) => {
    return (
        null
    )
}




class TemplateCarouselInternal extends React.Component {

    render() {

        return (
            <>
                <Header bg={"white"} />
                <div className='bg-white detailInside position-relative'>
                <div className="container-fluid p-100 p0">
                    {ExperiencesInternal.filter((item) => item.categoryInternal === this.props.categoryInternal).map((item, index) => (
                        <div key={index} className="row mb-5">
                            <div className="col-12">
                                <LinkHash key={"link" + index} className="text-black text-decoration-none ms-1 effectArrowLeft" smooth to={item.linkParentCategory} target="_self"><FontAwesomeIcon icon={faArrowLeft} /><span className="ps-4 text-uppercase"> {item.serviceNameParent} </span></LinkHash>
                            </div>
                        </div>
                    ))}
                    <InfiniteCarousel
                        showSides={true} 
                        sideSize={superLargeDesktop || desktop ? 0.5 : 0.2}
                        slidesToScroll={1}
                        adaptiveHeight={true}
                        slidesToShow={1}
                        nextArrow={<CustomRightArrow />}
                        prevArrow={<CustomLeftArrow />}>
                        
                        {ExperiencesInternal.filter((item) => item.category === this.props.category && item.categoryInternal === this.props.categoryInternal).map((item, index) => (
                            <ImgDetail key={index} linkImg={item.linkImgOne} title={item.serviceName} />
                        ))}
                        {ExperiencesInternal.filter((item) => item.category === this.props.category && item.categoryInternal === this.props.categoryInternal).map((item, index) => (
                            <ImgDetail key={index} linkImg={item.linkImgTwo} title={item.serviceName}  />
                        ))}
                        {ExperiencesInternal.filter((item) => item.category === this.props.category && item.categoryInternal === this.props.categoryInternal).map((item, index) => (
                            <ImgDetail key={index} linkImg={item.linkImgThree} title={item.serviceName} />
                        ))}
                    </InfiniteCarousel>
                </div >
                <div className="container mt-xs-25">
                    {ExperiencesInternal.filter((item) => item.category === this.props.category && item.categoryInternal === this.props.categoryInternal).map((item, index) => (
                        <div key={index} className="infoExperience text-center row justify-content-center">
                            <div className="col-md-10 col-12">
                                <h1 className="title">{item.serviceName}</h1>
                                <p className="pCust mb-xs-35" dangerouslySetInnerHTML={{ __html: item.description }}>
                                </p>
                            </div>
                            <div className="col-md-11 col-12">
                                <h5 className="subTitle-18 borderBox text-start text-uppercase">{item.TitleLeft1} <span className="float-end">{item.duration}</span> </h5>
                            </div>
                            <div className="col-md-11 col-12">
                                <h5 className="subTitle-18 py-2 text-start text-uppercase">{item.TitleLeft2} <span className="float-end">{item.price}</span></h5>
                            </div>
                            <div className="col-md-11 col-12 text-start mt-5" dangerouslySetInnerHTML={{ __html: item.htmlOne }}>
                            </div>
                            <div className="col-md-11 col-12 text-start mt-5" dangerouslySetInnerHTML={{ __html: item.htmlTwo }}>
                            </div>
                        </div>
                    ))}
                    <CTA />
                </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default TemplateCarouselInternal;