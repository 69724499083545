import React from "react";
import Button from 'react-bootstrap/Button';
import Cards from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { HashLink as LinkHash } from 'react-router-hash-link';

class Card extends React.Component {
    render() {
        return (
            <Cards>
                <Cards.Img variant="top" src={this.props.urlImg} />
                <Cards.Body>
                    <Cards.Title>{this.props.title}</Cards.Title>
                    <Cards.Text>
                        {this.props.description}
                    </Cards.Text>
                    {this.props.isAnchor ?
                        <LinkHash className="link lineEffectBlack effectArrow" smooth to={this.props.urlButton} target="_self">{this.props.titleButton} <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></LinkHash>
                    :

                    <Button className="effectArrow" href={this.props.urlButton} variant="primary">{this.props.titleButton} <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></Button>

                    }
                </Cards.Body>
            </Cards>
        )
    }
}

export default Card; 
