import React, { useState, useRef, useEffect } from 'react';
import Calendar from 'react-calendar';
import "../content/css/site.css";
import 'react-calendar/dist/Calendar.css';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';

function ReservationForm() {
    //#region value in the selects
    const experienceOptions = [
        {
            label: "Cruise"
        },
        {
            label: "Culinary Experiences"
        },
        {
            label: "In-Villa" 
        },
        {
            label: "Nature & Adventure"
        },
        {
            label: "Cultural Activities"
        },
        {
            label: "Wellness"
        },
        {
            label: "Private Transfers"
        },
        {
            label: "Memorable Experiences"
        }

    ];

    const TypeexperienceOptions = [
        {
            parent: "Cruise",
            label: "Capri Island"
        },
        {
            parent: "Cruise",
            label: "Amalfi Coast"
        },
        {
            parent: "Cruise",
            label: "Capri & Positano"
        },
        {
            parent: "Cruise",
            label: "Ischia & Procida"
        },
        {
            parent: "Cruise",
            label: "Sunset Cruise"
        },
        {
            parent: "Cruise",
            label: "Amalfi Coast Shared Cruise"
        },
        {
            parent: "Cruise",
            label: "Capri Island Shared Cruise"
        },
        {
            parent: "Cruise",
            label: "Dufour 390 Sailing Yacht"
        },
        {
            parent: "In-Villa",
            label: "In-Villa Private Chef"
        },
        {
            parent: "In-Villa",
            label: "In-Villa Pizza Chef"
        },
        // {
        //     parent: "Culinary Experiences",
        //     label: "Sunset Lounge & Mixology"
        // },
        {
            parent: "In-Villa",
            label: "In-Villa Cooking Class"
        },
        {
            parent: "In-Villa",
            label: "In-Villa Gourmet Dining"
        },
        {
            parent: "In-Villa",
            label: "In-Villa Breakfast"
        },
        {
            parent: "In-Villa",
            label: "Personal Grocery Shopper"
        },
        {
            parent: "Culinary Experiences",
            label: "Orto In Tavola Cooking Class"
        },
        {
            parent: "Culinary Experiences",
            label: "Wine Tasting"
        },
        {
            parent: "Culinary Experiences",
            label: "Olive Oil Tasting"
        },
        {
            parent: "Culinary Experiences",
            label: "MOZZARELLA MAKING"
        },
        {
            parent: "Culinary Experiences",
            label: "CRAFTING LIMONCELLO LIQUOR"
        },
        {
            parent: "Culinary Experiences",
            label: "AL FRESCO PICNIC EXPERIENCE"
        },
        {
            parent: "Nature & Adventure",
            label: "Path Of The Gods"
        },
        {
            parent: "Nature & Adventure",
            label: "The Mills Valley"
        },
        {
            parent: "Nature & Adventure",
            label: " Punta Campanella & Monte San Costanzo"
        },
        {
            parent: "Nature & Adventure",
            label: "Bay Of Jeranto"
        },
        {
            parent: "Nature & Adventure",
            label: "Freediving Course"
        },
        {
            parent: "Cultural Activities",
            label: "Pompei & Vesuvius"
        },
        {
            parent: "Cultural Activities",
            label: "Pompei or Vesuvius Getaway"
        },
        {
            parent: "Cultural Activities",
            label: "The Amalfi Coast Scenic Route"
        },
        {
            parent: "Wellness",
            label: "Massage"
        },
        {
            parent: "Wellness",
            label: "Facials & Beauty Treatments"
        },
        {
            parent: "Wellness",
            label: "Personal Trainer"
        },
        {
            parent: "Private Transfers",
            label: "From Naples To Your Villa"
        },
        {
            parent: "Private Transfers",
            label: "From Rome To Your Villa"
        },
        {
            parent: "Memorable Experiences",
            label: "Weddings & Events"
        },
        {
            parent: "Memorable Experiences",
            label: "Yoga Retreats"
        },
        {
            parent: "Memorable Experiences",
            label: "Car & Vespa Services"
        }       
    ];
    //#endregion

    const API_PATH = 'api/contact/index.php';
    const REACT_APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;
    const [date, onChangeDate] = useState("");
    const [selectedExperience, onClickExperience] = useState("");
    const [selectedTypeExperience, onClickTypeExperience] = useState("");
    const [visibleCalendar, onClickVisibleCalendar] = useState(false);
    const [openSelect, onClickOpenSelect] = useState(false);
    const [openSelectTypeExperience, onClickOpenSelectTypeExperience] = useState(false);
    const [mailSent, onSend] = useState(false);
    const [error, errorSend] = useState(null);
    const [fname, onChangeName] = useState("");
    const [lname, onChangeSurname] = useState("");
    const [email, onChangeEmail] = useState("");
    const [message, onChangeMessage] = useState("");
    const [phone, onChangePhone] = useState("");
    const [guest, onChangeGuest] = useState("");
    const [isAccepted, onClickTerms] = useState(false);
    const [isDisabled, onClickSend] = useState(false);
    const captchaRef = useRef(null);
    const formName = "reservation";

    const onClickHandeTerms = () => {
        if (isAccepted) {
            onClickTerms(false);
            onClickSend(false);
        } else {
            onClickTerms(true);
            onClickSend(true);
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
    }

    const onClickExperienceHandle = (val) => {
        onClickExperience(val);
        onClickTypeExperience("");
        onClickOpenSelect(false);
        onClickOpenSelectTypeExperience(true);
    }

    const onClickOpenTypeSelectHandle = (val) => {
        if (selectedExperience && !openSelectTypeExperience)
            onClickOpenSelectTypeExperience(true);
    }


    const onClickTypeExperienceHandle = (val) => {
        onClickTypeExperience(val);
        onClickOpenSelectTypeExperience(false);
    }

    const onChangeDateSelected = (val) => {
        onChangeDate(val.toDateString());
        onClickVisibleCalendar(false);
    }

    const handleFormSubmit = e => {
        e.preventDefault();
        onClickSend(false);
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: { fname, lname, email, message, phone, guest, isAccepted, date, selectedExperience, selectedTypeExperience, formName }
        })
            .then(result => {
                onSend(result.data.sent);
            })
            .catch(error => errorSend(error.message));
    };

    useEffect(() => {
        document.getElementById('formReservation').addEventListener('click', (e) => {
   
            if (e.target.id !== 'selectExp' && e.target.className !== 'lineEffectBlack') {
                onClickOpenSelect(false);
            }

            if (e.target.id !== 'selectTypeExp' && e.target.className !== 'lineEffectBlack') {
                onClickOpenSelectTypeExperience(false);
            }

            if (e.target.type !== 'button' && e.target.tagName !== 'ABBR' && e.target.className !== 'react-calendar') {
                onClickVisibleCalendar(false);
            }

        });
    }, []);




    return (
        <>
            <Header bg={"Transparent"} />
            <div id={"formReservation"} className="bg-black reservationForm">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h4 className="mb-3 text-center text-white title">Reservation</h4>
                            <p className="mb-5 text-center text-white paragraph">Contact us for pricing details and to book the experience.</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} >
                        <div className="row justify-content-center">
                            <div className="col-md-5 col-12 inputField">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="first name" value={fname} onChange={(x) => onChangeName(x.target.value)} required />
                                    <label htmlFor="fname">First Name*</label>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 inputField">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="last name" value={lname} onChange={(x) => onChangeSurname(x.target.value)} required />
                                    <label htmlFor="lname">Last Name*</label>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-5 col-12 inputField">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Email" value={email} onChange={(x) => onChangeEmail(x.target.value)} required />
                                    <label htmlFor="email">Email*</label>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 inputField">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={phone} onChange={(x) => onChangePhone(x.target.value)} placeholder="Phone number" />
                                    <label htmlFor="phone">Phone Number</label>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center position-relative">

                            <div className="col-md-5 col-12 inputField zIndex1">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="dateForm" name="dateStandard" placeholder="date"  defaultValue={date} disabled/>
                                    <div className="disabledDiv" onClick={event => onClickVisibleCalendar(true)}></div>
                                    <label htmlFor="date">Date</label>
                                </div>
                            </div>
                            {visibleCalendar ? <Calendar onChange={(newValue) => onChangeDateSelected(newValue)} value={date} /> : null}
                            <div className="col-md-5 col-12 inputField">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={guest} placeholder="2" onChange={(x) => onChangeGuest(x.target.value)} />
                                    <label htmlFor="guest">Number of guests</label>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center position-relative">
                            <div className="col-md-5 col-12 inputField zIndex1">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control textUp" placeholder="Experience"  defaultValue={selectedExperience} disabled />
                                    <div className="disabledDiv" onClick={(e) => onClickOpenSelect(true)}></div>
                                    <label htmlFor="selectedExperience">Experience</label>
                                </div>
                                <div id="selectExp" className="searchCustomDataSel">
                                    {experienceOptions.filter(x => {
                                        if (openSelect) {
                                            return x;
                                        }
                                    }).map((x, index) => (
                                        <a className="lineEffectBlack" onClick={event => onClickExperienceHandle(x.label)} key={index}>{x.label}</a>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-5 col-12 inputField zIndex1">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control textUp" placeholder="Type of Experience"  defaultValue={selectedTypeExperience} disabled/>
                                    <div className="disabledDiv" onClick={(e) => onClickOpenTypeSelectHandle()}></div>
                                    <label htmlFor="selectedTypeExperience">Type of Experience</label>
                                </div>
                                <div id="selectTypeExp" className="searchCustomDataSelRight">

                                    {TypeexperienceOptions.filter(x => {
                                        if (x.parent === selectedExperience && openSelectTypeExperience) {
                                            return x;
                                        }
                                    }).map((x, index) => (
                                        <a className="lineEffectBlack" onClick={event => onClickTypeExperienceHandle(x.label)} key={index}>{x.label}</a>
                                    ))}
                                </div>
                            </div>

                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-12 mt-5 inputField">
                                <div className="form-floating textarea mb-3">
                                    <input type="text" className="form-control" placeholder="message" value={message} onChange={(x) => onChangeMessage(x.target.value)} />
                                    <label htmlFor="message">Message</label>
                                </div>
                            </div>
                            <div className="col-md-10 col-12 m-0">
                                <p className="text-white m-0 paragraph-11">
                                    <input type="checkbox" className="m-0 me-2" value={isAccepted} onClick={(x) => onClickHandeTerms()} />
                                    I accept the <a href="privacy-policy" className="linkStd lineEffect">Privacy Policy</a> and <a href="terms-conditions" className="linkStd lineEffect">Terms & Conditions</a>.
                                </p>
                            </div>

                            <div className="col-md-10 col-12 text-right">
                                <div className="row">
                                <div className="col-md-12 col-12 textAlignMailSent">{mailSent ? <p className="text-white">Your message was successfully sent.</p> : <p className="text-white">{error}</p>} </div>
                                <div className="col-md-12 col-12"><button  onClick={e => handleFormSubmit(e)} className={"buttonSend lineEffect text-uppercase mr-30 " + (!isDisabled ? "disabledSend" : "" )}>Send</button></div>
                                </div>
                                <div className="invisible">
                                    <ReCAPTCHA sitekey={REACT_APP_SITE_KEY} ref={captchaRef} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default ReservationForm;
