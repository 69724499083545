import React, { useState } from "react";
import { HashLink as LinkHash } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Search from "../utilities/Search";
import Link from './Link';

const SearchFilter = () => {
    const [query, onChangeQuery] = useState("");

        return (
            <>
                <div className="input-group mb-3 search">
                    <input type="text" className="form-control br-0 b-none searchCustom" placeholder="Find your experience..." aria-label="Find your experience..." aria-describedby="Search-concierge" onChange={event => onChangeQuery(event.target.value )} />
                    <span className="input-group-text br-0 b-none bg-white" id="Search-concierge"><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                </div>
                <div className="search">
                    <div className="searchCustomData">
                        {Search.filter(x => {
                            if (query === '') {
                            } else if (x.name.toLowerCase().includes(query.toLowerCase()) && x.isSearchable === true) {
                                return x;
                            }
                        }).map((x, index) => (
                            x.isAnchor ? (
                                <LinkHash key={"link" + index} className="lineEffectBlack" smooth to={x.link} target="_self">{x.name}</LinkHash>
                            )
                                :
                                <Link key={"link" + index} href={x.link} text={x.name} className="lineEffectBlack" target="_self"></Link>
                        ))}
                    </div>
                </div>
            </>
        )

}

export default SearchFilter;