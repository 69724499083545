import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import MainPageCategory from '../utilities/MainPageCategory'
import { motion } from "framer-motion"

var boxRight = { 
  offscreen: {
    x: 500,
    opacity: 0,
    transition: { 
      duration: 1
    }
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.1,
      duration: 1.2
    }
  }
};

class HeadingSection extends React.Component {
  state = {
    isTop: true,
  };

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 1;
      if (isTop) {
        this.setState({ isTop: true })
      }
      else{
        this.setState({ isTop: false })
      }
    });
  }
  render() {
    return (
      MainPageCategory.map((item, index) => {
        return (
          this.props.position === item.category ?
            <div key={"heading" + item.id} className="sectionHeadingPage" style={{ backgroundImage: "url(" + item.urlImage + ")", backgroundPosition: item.positionBackground }}>
              <motion.div className="boxonRight" initial="offscreen" whileInView={this.state.isTop ? "onscreen" : "offscreen"} viewport={{ once: true, amount: 0.8 }}>
                <motion.div variants={boxRight} className="container-fluid position-absolute bottom20vh viewMobileHeading">
                  <div className="bg-white boxHeader">
                    <div className="input-group ms-2">
                      <h3>{item.rightBox}</h3>
                      <a href={item.urlCta} className="input-group-text nav-link effectArrow ctaWidthMax115 ctaLink " id="basic-addon1">Contact us <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></a>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
              <div className="container-fluid headingMobileSection">
                <h1 className="text-white title fw-200 mt-5">{item.title}</h1>
              </div>
            </div>
            : null
        );
      })
    );
  }
}

export default HeadingSection;
