//Here we have the info to create the main page for every single category e.g.: Cruise, Cultural Activities, etc.

const MainPageCategory = [
    {
        id:1,
        category: "Cruise",
        title: "Cruise Experiences",
        rightBox: "Let our Concierge help plan your memorable experiences",
        urlImage: require('../content/img/cruise/cruise-background.jpg'),
        positionBackground: "center",
        titleIntro: "Mediterranean Cruises",
        urlImageSection: require('../content/img/cruise/cruise-vertical.jpg'),
        altImageSection: "Discover the Amalfi Coast by sea",
        introduction: "Discover the Amalfi Coast by sea with our day cruises along the coastlines and charming bays of the Mediterranean.<br /><br /> Our bespoke cruises aboard a traditional Sorrentine Gozzo or elegant yachts are carefully curated in every detail. ",
        titleCarousel: "Featured itineraries",
        cta: "Contact us",
        urlCta: "/contact",
        carouselActive: true
    },
    {
        id:2,
        category: "CulinaryExperiences",
        positionBackground: "center",
        title: "Culinary Experiences",
        rightBox: "Let our Concierge help plan your memorable experiences",
        urlImage: require('../content/img/culinaryExperiences/culinary-experiences-background.jpg'),
        urlCta: "/contact",
        carouselActive: false
    },
    {
        id:3,
        category: "NatureAdventure",
        title: "Nature & Adventure",
        rightBox: "Let our Concierge help plan your memorable experiences",
        urlImage: require('../content/img/natureAdventure/natureAdventure-background.jpg'),
        positionBackground: "top",
        titleIntro: "Nature & Adventure",
        urlImageSection: require('../content/img/natureAdventure/natureAdventure-vertical.jpg'),
        altImageSection: "Hiking enthusiasts will immerse in enchanting sceneries where natural beauty",
        introduction: "Make the best of your vacation with Feeling Italy adventure travel experiences. <br> <br> Hiking enthusiasts will immerse in enchanting sceneries where natural beauty and local lifestyle provide an escape like no other.",
        titleCarousel: "Featured itineraries",
        cta: "Contact us",
        urlCta: "/contact",
        carouselActive: true
    },
    {
        id:4,
        category: "Cultural Activities",
        title: "Cultural Activities",
        rightBox: "Let our Concierge help plan your memorable experiences",
        urlImage: require('../content/img/culturalActivities/culturalActivities-background1.jpg'),
        positionBackground: "top",
        titleIntro: "DRIVING THROUGH THE CULTURAL WONDERS OF THE AMALFI COAST AND NAPLES",
        urlImageSection: require('../content/img/culturalActivities/culturalActivities-vertical.jpg'),
        altImageSection: "Embark on an unforgettable journey along the unique landscapes and historical sites",
        introduction: "Embark on an unforgettable journey along the unique landscapes and historical sites at your own pace.<br/><br/>Explore the treasures of the Amalfi Coast and Naples by private luxury van with an exclusive selection of day trips curated by Feeling Italy Concierge.",
        titleCarousel: "Featured itineraries",
        cta: "Contact us",
        urlCta: "/contact",
        carouselActive: false
    },
    // {
    //     id:5,
    //     category: "BespokeServices",
    //     title: "Bespoke Services",
    //     rightBox: "Let our Concierge help plan your memorable experiences",
    //     urlImage: require('../content/img/bespokeServices/bespoke-service-background.jpg'),
    //     positionBackground: "top", 
    //     urlCta: "/contact",
    //     carouselActive: false 
    // },
    {
        id: 6,
        category: "Wellness",
        title: "Wellness & SPA",
        positionBackground: "center",
        rightBox: "Let our Concierge help plan your memorable experiences",
        urlImage: require('../content/img/wellness/wellness-background.jpg'), 
        urlCta: "/contact",
        carouselActive: false
    },
    {
        id:7,
        category: "PrivateTransfers",
        title: "Private Transfers",
        positionBackground: "center",
        rightBox: "Let our Concierge help plan your memorable experiences",
        titleIntro: "",
        introduction: "Our concierge would be pleased to arrange a private transfer service for you with Mercedez-Benz cars and vans.",
        urlImage: require('../content/img/privateTransfers/private-transfers-background.jpg'),
        urlCta: "/contact",
        carouselActive: false
    },
    {
        id:8,
        category: "MemorableExperiences",
        title: "Memorable Experiences",
        positionBackground: "center",
        rightBox: "Let our Concierge help plan your memorable experiences",
        urlImage: require('../content/img/memorableExperiences/memorable-experiences-background.jpg'),
        urlCta: "/contact",     
    },
    {
        id: 9, 
        category: "In-Villa",
        title: "In-Villa Experiences",
        positionBackground: "center",
        rightBox: "Let our Concierge help plan your memorable experiences",
        urlImage: require('../content/img/inVilla/in-villa-background.jpg'),
        carouselActive: false,
        urlCta: "/contact",
    },
     {
        id:11,
        category: "CountryLifestyle",
        title: "Country Lifestyle",
        positionBackground: "center",
        rightBox: "Let our Concierge help plan your memorable experiences",
        urlImage: require('../content/img/culinaryExperiences/country-lifestyle-01.jpg'),
        urlCta: "/contact",
        carouselActive: false
    },
   
]


export default MainPageCategory;