import '../content/css/site.css';
import ScrollSpy from "react-ui-scrollspy";
import Header from '../components/Header';
import Footer from '../components/Footer';

function TermsConditions() {
    return (
        <>
        <Header bg={"white"} />

            <div className='bg-white pTop'>
                <div className='container'>
                    <div className='row mb-5'>
                        <div className='col-md-12 col-12'>
                            <h1 className="title mt-5 mb-3 text-center text-black">Terms & Conditions</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 col-12 boxMenuTermsConditions'>
                          
                                <ul className="navLeft">                              
                                <li data-to-scrollspy-id="conciergeReservations"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#conciergeReservations">Concierge reservations & cancellation policies</a></li>
                                <li data-to-scrollspy-id="insurancePolicy"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#insurancePolicy">Insurance Policy</a></li>
                                <li data-to-scrollspy-id="visitorsGatherings"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#visitorsGatherings">VISITORS & GATHERINGS</a></li>
                                <li data-to-scrollspy-id="limitation"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#limitation">LIMITATIONS</a></li>
                                <li data-to-scrollspy-id="complaints"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#complaints">COMPLAINTS</a></li>
                                <li data-to-scrollspy-id="guestObligations"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#guestObligations">GUEST’S OBLIGATIONS</a></li>
                                <li data-to-scrollspy-id="feelingItalyDataController"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#feelingItalyDataController">FEELING ITALY AS DATA CONTROLLER</a></li>
                                </ul>
                         
                        </div>
                        <div className='col-md-8 col-12 contentTab boxConditionsTermsConditions'>
                        <ScrollSpy activeClass="active" >
                            <h5 id="conciergeReservations" className="subTitle-18">CONCIERGE RESERVATIONS & CANCELLATION POLICIES</h5>
                            <p className="marginAccordion">For any cancellation related to lifestyle experiences and external services, please send your cancellation request to <a className="linkStd lineEffectBlack" href='mailto:concierge@feelingitaly.com'>concierge@feelingitaly.com</a>. Your cancellation will be valid as of email reception time.  When making your booking, it will be necessary for you to provide a deposit payment of the total amount, upon receipt of your booking confirmation.  The amount of the deposit requested varies in percentage, and depends on the activity booked. Balance payment is due on the day you booked the activity for. <br /><br />Lifestyle experiences and activities can be canceled free of charge up to 48 hours before the date the experience or activity is booked for. Any deposits paid will be fully refunded. If you send your cancellation request less than 48 hours before the arrival date, or in case of a no-show, 100% of the total amount will be charged.  
                            </p>
                            <h5 id="insurancePolicy" className="subTitle-18">INSURANCE POLICY</h5>
                            <p className="marginAccordion">We strongly recommend that you, and all members of your party, purchase travel insurance which includes coverage against trip cancellation due to illness or death. Appropriate coverage should be purchased at the time of the booking. Please note that in the unfortunate event of a cancellation due to any events, occurrences or causes, including health reasons, cancellation terms will irrevocably apply.</p>
                            <h5 id="visitorsGatherings" className="subTitle-18">VISITORS & GATHERINGS</h5>
                            <p className="marginAccordion">You agree to the terms and conditions of purchase imposed by Feeling Italy. Each property has a maximum number of guests allowed. You understand and accept that the number of people must not exceed the number of sleeping places stated on the property description. Should the house manager discover more people onsite than the maximum number allowed, as per property description, the extra people may be requested to vacate the house.</p>
                            <h5 id="limitation" className="subTitle-18">LIMITATIONS</h5>
                            <p className="marginAccordion">Should you intend to use one of our villas or apartments for any type of commercial use, such as photo shootings or filming, please immediately inform Feeling Italy of this at the time of booking. Such use will only be allowed with our written consent. You are not allowed to organize any private parties, weddings, wedding receptions or large gatherings on our properties without our written permission, unless clearly specified in the description. Furthermore, services provided by external suppliers, such as private chefs, catering, among others, are not permitted in Feeling Italy properties. In such cases, you will be asked to pay an extra fee for your event or service. In such cases, you will be asked to pay an extra fee for your event. No camping is permitted on any property grounds.</p>
                            <h5 id="complaints" className="subTitle-18">COMPLAINTS</h5>
                            <p className="marginAccordion">We regularly visit and check each property, in order to welcome you with the highest possible standards. Breakdowns beyond the control of Feeling Italy, such as cuts in the electricity, water or gas supply, and/or internet connection may occur, and these are the responsibility of the company’s service providers. Should this occur during your stay at one of our properties, we will do our best to solve the problem at earliest convenience. <br /><br />If you have any issues during your stay, please do not hesitate to contact either the house-manager or our office immediately. Any complaints lodged at the end or after the stay on our property will not be taken into consideration. If you leave the property without informing us, you will lose all rights to claim any possible refund, or rebate.</p>
                            <h5 id="guestObligations" className="subTitle-18">GUEST'S OBLIGATIONS</h5>
                            <p className="marginAccordion">The person signing the contract is responsible for the correct and decent behavior of his party. Should the client and his party exhibit unacceptable behavior, the key holder may ask the client and his party to vacate the house. <br /><br />Any wood ovens available on our properties can solely be used by the professional pizza chefs provided to you by Feeling Italy. Please contact Feeling Italy to enquire about the cost and availability of this service on the property of your choice. Wood oven use for self-catering or use by external pizza chefs is not allowed, under any circumstance.</p>
                            <h5 id="feelingItalyDataController" className="subTitle-18">FEELING ITALY AS DATA CONTROLLER</h5>
                            <p className="marginAccordion">DATA PROTECTION (Organic Law 15/1999 of 13th December)<br />In compliance with the Organic Law 15/1999 of 13th December, on Protection of Personal Data ("LOPD"), Feeling Italy collects personal data, voluntarily provided by the client, to carry out all the activities related to our service.  The client is encouraged to consult the Privacy Policy section on the extent of personal data communication for further information.</p>
                            </ScrollSpy>
                        </div>
                       
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default TermsConditions;
