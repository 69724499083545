import React from "react";
import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuLink from '../utilities/MenuLink';
import Link from './Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import companyLogo from '../content/img/logo-w.png';
import { HashLink as LinkHash } from 'react-router-hash-link';
import CookieBanner from "./CookieBanner";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let consent = cookies.get('acceptance') !== undefined ? true : false;

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showBanner:false
    }
    this.clickHandler = this.clickHandler.bind(this)
    this.changeState.bind(this);
  }
  clickHandler() {
    this.setState({
      showBanner:true
    });
  }
  changeState = (newValue) => {
    this.setState({ showBanner: newValue });
 };
 

  render() {
    return (
      <footer key={"footer"} className="bg-black">
        <div className="container custom-width">
          <div className="row text-center text-white">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 p-5">
              <img className="w-300" src={companyLogo} alt="Feeling Italy logo" />
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 p-3 customSectionFooter">
              <h5 className="text-uppercase header-footer">About</h5>
              {MenuLink.map((item, index) => {
                return (
                  item.position === "Footer" && item.section === "About" && item.show === true ? (
                    <div key={"Link" + index} >
                      {item.anchor === true ? (
                        <LinkHash key={"link" + index} className={item.class} smooth to={item.href} target={item.target}>{item.text}</LinkHash>
                      )
                        :
                        <Link key={"link" + index} href={item.href} smooth text={item.text} class={item.class} target={item.target}></Link>}
                    </div>)
                    :
                    null
                );
              })}
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 p-3 customSectionFooter">
              <h5 className="text-uppercase header-footer">Experiences</h5>
              {MenuLink.map((item, index) => {
                return (
                  item.position === "Footer" && item.section === "Experiences" && item.show === true ? (
                    <div key={index}>
                      {item.anchor === true ? (
                        <LinkHash key={"link" + index} className={item.class} smooth to={item.href} target={item.target}>{item.text}</LinkHash>
                      )
                        :
                        <Link key={"link" + index} href={item.href} text={item.text} class={item.class} target={item.target}></Link>}
                    </div>)
                    :
                    null
                );
              })}
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 p-3 customSectionFooter">
              <h5 className="text-uppercase header-footer">Services</h5>
              {MenuLink.map((item, index) => {
                return (
                  item.position === "Footer" && item.section === "Services" && item.show === true ? (
                    <div key={item.id} >
                      {item.anchor === true ? (
                        <LinkHash key={"link" + index} className={item.class} smooth to={item.href} target={item.target}>{item.text}</LinkHash>
                      )
                        :
                        <Link key={"link" + index} href={item.href} text={item.text} class={item.class} target={item.target}></Link>}
                    </div>)
                    :
                    null
                );
              })}
            </div>
          </div>

          <div className="row pt-5 pb-3 px-3 borderBottSocial">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 p-0">
              <Link target='_blank' href='https://www.facebook.com/FeelingItaly' key={"Facebook"} text={<FontAwesomeIcon icon={faFacebookF} />} className='socialLink'></Link>
              <Link target='_blank' href='https://www.instagram.com/feelingitaly/' key={"Instagram"} text={<FontAwesomeIcon icon={faInstagram} />} className='socialLink'></Link>
            </div>
          </div>

          <div className="row pt-3 pb-3 px-3">
            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 p-0 privacy-start">
              {MenuLink.map((item) => {
                return (
                  item.position === "Footer" && item.section === "Copyright" && item.show === true ?
                    item.text === "Cookie Preferences" ?
                      <button key={item.href} className="buttonCookie lineEffect" onClick={(e) => this.clickHandler()}>{item.text}</button>
                      :
                      <Link key={item.href} href={item.href} text={item.text} class={item.class} target={item.target}></Link>
                    : null
                )
              }
              )}

            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 p-0 copy-end">
              <p className="text-white copyright">&copy; Feeling Italy {new Date().getFullYear()}. All Rights Reserved.</p>
            </div>
          </div>
        </div>    
        {(this.state.showBanner || !consent) &&
          <CookieBanner changeState  = {this.changeState} showBanner = {this.state.showBanner}/>
        }
        
      </footer>

    )
  }
}

export default Footer;
