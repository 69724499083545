import '../content/css/site.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ScrollSpy from "react-ui-scrollspy";

function PrivacyPolicy() {
    return (
        <>
        <Header bg={"white"} />
        <div className='bg-white pTop'>
                <div className='container'>
                    <div className='row mb-5'>
                        <div className='col-md-12 col-12'>
                            <h1 className="title mt-5 mb-3 text-center text-black">Privacy Policy</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 col-12 boxMenuTermsConditions'>
                          
                                <ul className="navLeft">                              
                                <li data-to-scrollspy-id="privacyPolicy"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#privacyPolicy">Privacy Policy</a></li>
                                <li data-to-scrollspy-id="dataController"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#dataController">DATA CONTROLLER</a></li>
                                <li data-to-scrollspy-id="dataCollected"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#dataCollected">TYPES OF DATA COLLECTED AND PURPOSE OF PROCESSING</a></li>
                                <li data-to-scrollspy-id="provisionData"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#provisionData">PROVISION OF PERSONAL DATA</a></li>
                                <li data-to-scrollspy-id="locationData"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#locationData">LOCATION OF COLLECTED PERSONAL DATA</a></li>
                                <li data-to-scrollspy-id="cookiesTags"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#cookiesTags">COOKIES AND TAGS</a></li>
                                <li data-to-scrollspy-id="dataProcessingMethods"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#dataProcessingMethods">DATA PROCESSING METHODS</a></li>
                                <li data-to-scrollspy-id="communicationPersonalData"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#communicationPersonalData">COMMUNICATION OF PERSONAL DATA</a></li>
                                <li data-to-scrollspy-id="storagePeriod"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#storagePeriod">STORAGE PERIOD</a></li>
                                <li data-to-scrollspy-id="rightsData"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#rightsData">RIGHTS OF THE DATA SUBJECT</a></li>
                                <li data-to-scrollspy-id="updateModifications"><a className="list-group-item list-group-item-action linkAccordioneSidebar" href="#updateModifications">UPDATES AND MODIFICATIONS</a></li>
                                </ul>
                         
                        </div>
                        <div className='col-md-8 col-12 contentTab boxConditionsTermsConditions'>
                        <ScrollSpy activeClass="active" >

                            <h5 id="privacyPolicy" className="subTitle-18">PRIVACY POLICY</h5>
                            <p className="marginAccordion">This Privacy Policy applies to the website <a className="linkStd lineEffectBlack" href='https://concierge.feelingitaly.com'>concierge.feelingitaly.com</a> concierge.feelingitaly.com and governs the processing of personal data carried out by Feeling Italy in order to provide concierge experiences and services and, with the user's consent, for marketing purposes. The information provided below is in compliance with the regulations set forth by Legislative Decree no. 196/2003 and EU Regulation no. 679/2016 on the protection of personal data.  
                            </p>
                            <h5 id="dataController" className="subTitle-18">DATA CONTROLLER</h5>
                            <p className="marginAccordion">Feeling Italy – Gente di Mare S.R.L. Corso S. Agata 61 N, 80061 Massa Lubrense NA IT P.IVA 04831951217 Email: <a className="linkStd lineEffectBlack" href='mailto:concierge@feelingitaly.com'>concierge@feelingitaly.com</a></p>
                            <h5 id="dataCollected" className="subTitle-18">TYPES OF DATA COLLECTED AND PURPOSE OF PROCESSING</h5>
                            <p className="marginAccordion">Feeling Italy collects and processes personal data, including name, surname, address, telephone number, email address, and any other optional information. This data is voluntarily provided by the user for the purpose of promoting concierge lifestyle experiences and services through the website. Feeling Italy may also process personal data to enforce or defend legal rights and fulfill obligations required by laws, regulations, and EU legislation. The collection of personal data by Feeling Italy is in accordance with the provisions of Article 5 of Regulation No. 679/2016, ensuring that only relevant and necessary data is collected to carry out the promotion of experiences and services.</p>
                            <h5 id="provisionData" className="subTitle-18">PROVISION OF PERSONAL DATA</h5>
                            <p className="marginAccordion">The provision of data by the user is necessary for the performance of promoting experiences and services through the website www.concierge.feelingitaly.com. Additionally, the provision of personal data by the user is mandatory to comply with legislative provisions. Failure to provide personal data in the mentioned cases will make it impossible for the user to receive services, requested experiences, and information from the website concierge.feelingitaly.com. However, providing data for marketing purposes is optional, and failure to do so will have no consequences.</p>

                            <h5 id="locationData" className="subTitle-18">LOCATION OF COLLECTED PERSONAL DATA</h5>
                            <p className="marginAccordion">The personal data collected will be processed by Feeling Italy. For further information on the extent of personal data communication, users are encouraged to consult the Privacy Policy section.</p>

                            <h5 id="cookiesTags" className="subTitle-18">COOKIES AND TAGS</h5>
                            <p className="marginAccordion">Cookies are data files stored on the user's computer's hard drive and used after the user registers with the site. The website employs cookies and tags for specific purposes, such as facilitating navigation, improving access to the site, enhancing internal security, conducting statistical analysis, and facilitating system administration. Session cookies are stored temporarily and are deleted when the user closes the browser. The cookies and tags used by Feeling Italy do not grant control over the user's computer or access to stored information or monitoring of user operations. Feeling Italy cannot be held responsible for cookies and other technologies used by third-party websites accessible through links on the site.</p>

                            <h5 id="dataProcessingMethods" className="subTitle-18">DATA PROCESSING METHODS</h5>
                            <p className="marginAccordion">The Data Controller processes personal data in compliance with national privacy legislation and EU Regulation No. 679/2016. Processing is primarily conducted electronically, but manual processing may also occur with strict organizational methods and logics related to the specified purposes. Feeling Italy ensures compliance with security provisions to minimize the risk of data destruction, loss, unauthorized access, unauthorized processing, unlawful or incorrect use of data.</p>

                            <h5 id="communicationPersonalData" className="subTitle-18">COMMUNICATION OF PERSONAL DATA</h5>
                            <p className="marginAccordion">The user's personal data will be accessible to Feeling Italy employees responsible for data processing for the specified purposes. A complete and updated list of data processors and entities to whom data is communicated can be requested in writing to Feeling Italy. Personal data may be communicated to professionals, collaborators, legal entities, and third parties providing technical and organizational services to Feeling Italy. These entities will process the data as appropriate, strictly following the purposes mentioned and in compliance with relevant regulations. Personal data will not be disclosed in any other way.</p>

                            <h5 id="storagePeriod" className="subTitle-18">STORAGE PERIOD</h5>
                            <p className="marginAccordion">In accordance with Regulation No. 679/2016, personal data is processed for the duration necessary to fulfill the requested services. Users have the right to request the interruption of processing or the deletion of their data. It is important to note that due to the various obligations involved, personal data of users will be stored for a period not exceeding 38 months.</p>
                            
                            <h5 id="rightsData" className="subTitle-18">RIGHTS OF THE DATA SUBJECT</h5>
                            <p className="marginAccordion">Under national legislation (Art. 7 d.lgs. 196/2003) and Articles 15 to 22 of EU Regulation No. 679/2016, users have the following rights:
                            <ul>
                                <li>
                                The right to access personal data.
                                </li>
                                <li>
                                The right to request correction, deletion, or restriction of the processing of personal data.
                                    </li>
                                    <li>
                                    The right to obtain information about the purposes of processing, the categories of personal data, the recipients or categories of recipients to whom the personal data have been or will be disclosed, and, when possible, the storage period.
                                    </li>
                                    <li>
                                    The right to object to the processing of data.
                                    </li>
                                    <li>
                                    The right to revoke consent at any time, without affecting the lawfulness of processing based on consent before revocation.
                                    </li>
                                    <li>
                                    The right to lodge a complaint with the competent supervisory authority.
                                    </li>
                            </ul>
                            Users can exercise their rights by sending a request via email to <a className="linkStd lineEffectBlack" href='mailto:concierge@feelingitaly.com'>concierge@feelingitaly.com</a>
                            </p>

                            <h5 id="updateModifications" className="subTitle-18">UPDATES AND MODIFICATIONS</h5>
                            <p className="marginAccordion">This privacy policy is a dynamic document subject to regular updates. Therefore, the Data Controller reserves the right to make changes at any time, with notice provided by publishing the updated policy on this page.</p>


                            </ScrollSpy>
                        </div>
                       
                    </div>
                    </div></div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy;
