import React from 'react';
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";

const AdvancedBanner = ({ item, mobile }) => {
    function showModal(index) {
        var modal = document.getElementById("modal" + index);
        document.body.style.overflow = 'hidden';
        modal.classList.add('show');
    };

    return (

        <>
                <ParallaxBanner id={item.anchor} style={{ aspectRatio: '2 / 1' }} className={`${item.category === "BespokeServices" || item.category === "Wellness" ? "sectionSliderPageCustom" : ""} sectionSliderPage backgroundOverlay`}>
                    <ParallaxBannerLayer
                        expanded={false}
                        translateY={[0, -30]}
                        speed={-20}
                        // scale={[1, 1.1]}
                        // opacity={[0.9, 1]}
                        // easing = {[1, 0.75, 0.5, 1.34]} 
                        image={!mobile ? item.imgUrl : item.imgMobile}
                        opacity={[0, 1, "easeOutCubic"]}

                    >

                    </ParallaxBannerLayer>

                    <ParallaxBannerLayer >
                        <div className="container-fluid h-100 position-relative">
                            <div className="boxHeading2 boxHeading4"></div>
                            <div className="boxHeading boxHeading3"></div>
                            <div className="box50">
                                <h1 className="text-white title">
                                    {item.title}
                                </h1>
                                <p className="text-white mt-4" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                <div className="contentSlider mb-3 text-center w-100 font-12">
                                    {item.isModal ? (
                                        <button onClick={() => showModal(item.id)} className="lineEffect link buttonDetails mt-2 mb-4" id="basic-addon1">{item.buttonText}</button>)
                                        :
                                        <>
                                            <p className="mb-3 text-white text-uppercase" dangerouslySetInnerHTML={{ __html: item.price }}></p>
                                            <a href={item.buttonUrl} className="lineEffect link buttonDetails mb-4" id="basic-addon1">{item.buttonText}</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </ParallaxBannerLayer>

                </ParallaxBanner>
       </>

        /* <ParallaxBanner layers={[background, headline]} className="full" /> */
    );
}

export default AdvancedBanner;