const MenuMobile = [

  // HEADER

  {
    id:1,
    position: "Header",
    section: "",
    order: 0,
    text: "Cruise",
    href: "/cruise",
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false
  },
  {
    id:2,
    position: "Header",
    section: "",
    order: 1,
    text: "Culinary Experiences",
    href: "/culinary-experiences",
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false
  },
  {
    id: 10, 
    position: "Header",
    section: "",
    order: 9, 
    text: "In Villa",
    href: "/in-villa", 
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false,
  },
  {
    id:3,
    position: "Header",
    section: "",
    order: 2,
    text: "Nature & Adventure",
    href: "/nature-adventure",
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false
  },
  {
    id:4,
    position: "Header",
    section: "",
    order: 3,
    text: "Cultural Activities",
    href: "/cultural-activities",
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false
  },
  // {
  //   id:5,
  //   position: "Header",
  //   section: "",
  //   order: 4,
  //   text: "Bespoke Services",
  //   href: "/bespoke-services",
  //   show: true,
  //   class: "link-header ",
  //   target: "_self",
  //   anchor: false
  // },
  {
    id:6,
    position: "Header",
    section: "",
    order: 5,
    text: "Wellness",
    href: "/wellness",
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false
  },
  {
    id:7,
    position: "Header",
    section: "",
    order: 6,
    text: "Private Transfers",
    href: "/private-transfers",
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false
  },
  {
    id: 8,
    position: "Header",
    section: "",
    order: 7,
    text: "Memorable Experiences",
    href: "/memorable-experiences",
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false
  },
  {
    id: 9,
    position: "Header",
    section: "",
    order: 8,
    text: "Contact Us",
    href: "/contact",
    show: true,
    class: "link-header ",
    target: "_self",
    anchor: false
  },
  

];

export default MenuMobile;