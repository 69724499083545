import React, { useState, useRef } from 'react';

import "../content/css/site.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Footer from '../components/Footer';
import Header from '../components/Header';

function Contact() {
    const REACT_APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;

    const API_PATH = 'api/contact/index.php';
    const captchaRef = useRef(null);
    const formName = "contact";

    const [mailSent, onSend] = useState(false);
    const [error, errorSend] = useState(null);
    const [fname, onChangeName] = useState("");
    const [lname, onChangeSurname] = useState("");
    const [email, onChangeEmail] = useState("");
    const [message, onChangeMessage] = useState("");
    const [phone, onChangePhone] = useState("");
    const [isAccepted, onClickTerms] = useState(false);
    const [isDisabled, onClickSend] = useState(false);

    const onClickHandeTerms = () => {
        if (isAccepted) {
            onClickTerms(false);
            onClickSend(false);
        } else {
            onClickTerms(true);
            onClickSend(true);
        }
    }

    // const handleSubmit = (e) =>{
    //     e.preventDefault();
    //     const token = captchaRef.current.getValue();
    //     captchaRef.current.reset();
    // }

    const handleFormSubmit = e => {
        e.preventDefault();
        onClickSend(false);
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: { fname, lname, email, message, phone, isAccepted, formName }
        })
            .then(result => {
                onSend(result.data.sent);
            })
            .catch(error => errorSend(error.message));
    };

    return (
        <>
            <Header bg={"white"} />
            <div className="bg-white contactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-5 text-center text-black title">Contact Information</h1>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-10 col-12">
                            <div className="borderContact">
                                <span className="infoTitle">Phone</span>
                                <span className="float-end infoText"><a href="tel:+3908119312378">+39 081 193 123 78</a></span>
                            </div>
                        </div>
                        <div className="col-md-10 col-12">
                            <div className="borderContact">
                                <span className="infoTitle">Mail</span>
                                <span className="float-end infoText"><a className="" href="mailto:concierge@feelingitaly.com">concierge@feelingitaly.com</a></span>
                            </div>
                        </div>
                        <div className="col-md-10 col-12">
                            <div className="borderContact">
                                <span className="infoTitle">Address</span>
                                <span className="float-end infoText">Corso Sant'Agata 62, 80061 Massa Lubrense, Italy</span>
                            </div>
                        </div>
                    </div>
                    <form action="#">
                        <div className="row justify-content-center inputField mt-10 mt-xs-5">
                            <div className="col-md-5 col-12 mb-3">
                                <div className="form-floating">

                                    <input type="text" className="form-control" placeholder="First Name" value={fname} onChange={(x) => onChangeName(x.target.value)} required />
                                    <label htmlFor="fname">First Name*</label>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 mb-3">
                                <div className="form-floating">

                                    <input type="text" className="form-control" placeholder="Last Name" value={lname} onChange={(x) => onChangeSurname(x.target.value)} required />
                                    <label htmlFor="lname">Last Name*</label>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 mb-3">
                                <div className="form-floating">

                                    <input type="text" className="form-control" placeholder="Email" value={email} onChange={(x) => onChangeEmail(x.target.value)} required />
                                    <label htmlFor="email">Email*</label>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 mb-3">
                                <div className="form-floating">

                                    <input type="text" className="form-control" placeholder="Phone Number" value={phone} onChange={(x) => onChangePhone(x.target.value)} />
                                    <label htmlFor="phone">Phone Number</label>
                                </div>
                            </div>


                            <div className="col-md-10 col-12 mt-5 mb-3">
                                <div className="form-floating textarea">
                                    <input type="text" className="form-control" placeholder="Message" value={message} onChange={(x) => onChangeMessage(x.target.value)} />
                                    <label htmlFor="message">Message</label>
                                </div>
                            </div>
                            <div className="col-md-10 col-12 m-0">
                                <p className="text-black m-0 paragraph-11">
                                    <input type="checkbox" className="m-0 me-2" value={isAccepted} onClick={(x) => onClickHandeTerms()} />
                                    I accept the <a href="privacy-policy" className="linkStd lineEffectBlack">Privacy Policy</a> and <a href="terms-conditions" className="linkStd lineEffectBlack">Terms & Conditions</a>.
                                </p>
                            </div>
                            <div className="col-md-10 col-12 text-right">
                            <div className="row">
                                <div className="col-md-12 col-12 textAlignMailSent">{mailSent ? <p>Your message was successfully sent.</p> : <p>{error}</p>} </div>
                                <div className="col-md-12 col-12"><button  onClick={e => handleFormSubmit(e)} className={"buttonSend lineEffectBlack text-uppercase mr-30 " + (!isDisabled ? "disabledSend" : "" )}>Send</button></div>                                </div>
                                <div className="invisible">
                                    <ReCAPTCHA sitekey={REACT_APP_SITE_KEY} ref={captchaRef} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact;
