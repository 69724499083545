//Here we have the info to create the main page for every single category e.g.: Cruise, Cultural Activities, etc.

const SliderPageCategory = [
    // #region Culinary Experiences
    {
        id: 5,
        category: "CulinaryExperiences",
        anchor:"OrtoInTavola",
        //imgUrl: require('../content/img/culinaryExperiences/farm-to-table.jpg'),
        //imgMobile: require('../content/img/culinaryExperiences/farm-to-table-mobile.jpg'),
        imgUrl: require('../content/img/culinaryExperiences/farm-to-table.jpg'),
        imgMobile: require('../content/img/culinaryExperiences/farm-to-table-mobile.jpg'),
        title: "ORTO IN TAVOLA COOKING CLASS",
        positionBackground: "top",
        description: "Join Chef Mimmo De Gregorio for an extraordinary culinary experience and one-of-a kind private cooking class. Set within the Orto Ghezi, the Chef’s private vegetable garden, the open-air class starts with a deeper connection to the land learning the planting, growing and harvesting process of the ingredients used in his kitchen. Your culinary journey continues at Lo Stuzzichino restaurant, where our Chef combines tradition and innovation in a unique location. You will learn how to create signature dishes introducing sophisticated flavors, textures and traditional cooking techniques with the finest farm-to-table produce.",
        price: "FROM €120.00 <br> Minimum 4 persons, maximum 12 persons",
        buttonText: "Book your experience",
        buttonUrl: "/reservation-form",
        detail: [],
        isModal: false
    },
    {
        id: 6,
        category: "CulinaryExperiences",
        anchor:"WineTasting",
        //imgUrl: require('../content/img/culinaryExperiences/wine-tasting.jpg'),
        //imgMobile: require('../content/img/culinaryExperiences/wine-tasting-mobile.jpg'),
        imgUrl: require('../content/img/culinaryExperiences/wine-tasting.jpg'), 
        imgMobile: require('../content/img/culinaryExperiences/wine-tasting-mobile.jpg'),
        title: "WINE TASTING",
        positionBackground: "top",
        description: "Our Wine Cellar is an intimate space designed with stone and wood finishes and handcrafted majolica walls stacked with a curated selection of Italian wine. Gather around the Chef’s Table and with an expert sommelier, learn about the region’s white and red wines and taste the intense flavors paired with a platter of exquisite Provolone del Monaco cheese and other delicious bites. Our wine tasting experience is the perfect treat for connoisseurs.",
        price: "FROM €90.00 <br> Minimum 4 persons",
        buttonText: "Book your experience",
        buttonUrl: "/reservation-form",
        detail: [],
        isModal: false
    },
    {
        id: 7,
        category: "CulinaryExperiences",
        anchor:"OilTasting",
        imgUrl: require('../content/img/culinaryExperiences/oil-olive-gourmet-tasting.jpg'),
        imgMobile: require('../content/img/culinaryExperiences/oil-olive-gourmet-tasting-mobile.jpg'),
        title: "OLIVE OIL TASTING",
        positionBackground: "top",
        description: "Savour the aromas of Italian cuisine, connect directly with local farmers who will introduce you to the tradition of extra virgin olive oil production, limoncello and local gastronomy from Campania region.<br><br> Gather in the charming countryside and immerse yourself in the olive and lemon groves while tasting the varieties of cheese such as Provolone del Monaco, mozzarella and ricotta freshly made for you – a truly authentic and memorable experience.",
        price: "FROM €170,00",
        buttonText: "Book your experience",
        buttonUrl: "/reservation-form",
        detail: [],
        isModal: false
    },
    {
        id: 8,
        category: "CulinaryExperiences",
        anchor: "CountryLifestyle",
        imgUrl: require('../content/img/culinaryExperiences/country-lifestyle.jpg'),
        imgMobile: require('../content/img/culinaryExperiences/country-lifestyle-mobile.jpg'),
        title: "COUNTRY LIFESTYLE",
        positionBackground: "top",
        description: "Indulge in an immersive country-style experience and connect with the essence of authentic Italian gastronomy. Our private, hands-on workshops invite you to explore local traditions, savor Mediterranean flavors, and learn secret techniques passed down through generations.",
        buttonText: "View more",
        buttonUrl: "culinary-experiences/country-lifestyle", 
        detail: [],
        isModal: false
    },
    // #endregion Culinary Experiences


    // #region Wellness
    {
        id: 1,
        category: "Wellness",
        anchor:"Massage",
        imgUrl: require('../content/img/wellness/massage.jpg'),
        imgMobile: require('../content/img/wellness/massage-mobile.jpg'),
        title: "Massage",
        positionBackground: "center",
        description: "Feeling Italy Concierge can arrange a personalized spa in the comfort of your villa. Our wellness experience offers a range of massages and treatments to unwind and relax during your holiday. All treatments are tailored to your body’s needs.",
        price: "",
        buttonText: "Details",
        buttonUrl: "/reservation-form",
        categoryDetail: "Massage",
        detail: [
            {
                id: 1,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "RELAXING MASSAGE",
                description: "Designed for ultimate relaxation, this massage helps ease muscle tension, calm the senses and reduce stress. An ideal choice to tackle aching muscles and body tension.",
                price: "€95,00",
                duration: "DURATION: 50 MINUTES",
            },
            {
                id: 2,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "FACIAL MASSAGE",
                description: "Reactivating the microcirculation, this gentle facial massage smooths out the skin surface and makes it more toned.",
                price: "€85,00",
                duration: "DURATION: 30 MINUTES",
            },
            {
                id: 3,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "CONNECTIVE TISSUE MASSAGE",
                description: "This massage improves muscle performance and deeply releases body tension. The body gains flexibility and ease of movement.",
                price: "€105,00",
                duration: "DURATION: 50 MINUTES",
            },
            {
                id: 4,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "ESSENTIAL OIL MASSAGE",
                description: "This essential oil massage stimulates your senses and balances both mind and body. The oil aroma helps ease muscle tension and allow your body to deeply relax.",
                price: "€95,00",
                duration: "DURATION: 50 MINUTES",
            },
            {
                id: 5,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "AMALFI COAST CITRUS MASSAGE",
                description: "Designed for ultimate relaxation, the aromatic citrus oil massage is meant to exfoliate and purify your body. A signature treatment to rejuvenate your skin and reduce stress.",
                price: "€95,00",
                duration: "DURATION: 50 MINUTES",
            },
            {
                id: 6,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "BACK, NECK & SHOULDER MASSAGE",
                description: "The back, neck and shoulders massage help ease muscle and body tension and improves blood circulation.",
                price: "€85,00",
                duration: "DURATION: 30 MINUTES",
            },
            {
                id: 7,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "FOOT REFLEXOLOGY",
                description: "This therapy uses gentle pressure on specific points along your feet to help release stress, tension and restore the flow of energy throughout the body.",
                price: "€85,00",
                duration: "DURATION: 30 MINUTES",
            },
            {
                id: 8,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "THAI MASSAGE",
                description: "This massage stimulates the circulation and flow of energy through gentle pressure and stretching techniques. The body regains flexibility and the mind returns to a positive balance.",
                price: "€105,00",
                duration: "DURATION: 50 MINUTES",
            },
            {
                id: 9,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "AYURVEDIC HEAD MASSAGE",
                description: "This deep massage with aromatic essential oils helps ease the scalp tension and allows your mind to relax and calm the senses. This massage also nourishes your hair and stimulates the natural healing abilities of your body.",
                price: "€95,00",
                duration: "DURATION: 45 MINUTES",
            },
            {
                id: 10,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "AYURVEDIC FULL BODY MASSAGE",
                description: "This massage with aromatic essential oils helps ease muscle tension, detoxifies, and revitalizes the entire body. It is strongly recommended for those who suffer from insomnia, digestive disorders, and stress.",
                price: "€105,00",
                duration: "DURATION: 60 MINUTES",
            },
            {
                id: 11,
                category: "Wellness",
                categoryDetail: "Massage",
                title: "LYMPHATIC DRAINAGE MASSAGE",
                description: "This massage helps release tension at a deeper level. The therapy uses light to medium pressures to stimulate circulation and remove accumulated toxins.",
                price: "€105,00 - €135,00",
                duration: "DURATION: 60 - 90 MINUTES",
            }

        ],
        isModal: true
    },
    {
        id: 2,
        category: "Wellness",
        anchor:"FacialsBeautyTreatments",
        imgUrl: require('../content/img/wellness/facials-beauty-treatments.jpg'),
        imgMobile: require('../content/img/wellness/facials-beauty-treatments-mobile.jpg'),
        title: "FACIALS & BEAUTY TREATMENTS",
        positionBackground: "top",
        description: "Let yourself be pampered with our bespoke revitalizing facial rituals and beauty treatments in the comfort of your villa.",
        price: "",
        duration: "",
        buttonText: "Details",
        buttonUrl: "/reservation-form",
        categoryDetail: "FacialsBeauty",
        detail: [
            {
                id: 1,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "WAXING FULL LEG & BIKINI",
                description: "",
                price: "€80,00",
                duration: ""
            },
            {
                id: 2,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "WAXING BIKINI",
                description: "",
                price: "€50,00",
                duration: ""
            },
            {
                id: 3,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "MANICURE",
                description: "",
                price: "€50,00",
                duration: ""
            },
            {
                id: 4,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "PEDICURE",
                description: "",
                price: "€60,00",
                duration: ""
            },
            {
                id: 5,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "FACIALS",
                description: "",
                price: "ON REQUEST",
                duration: ""
            },
            {
                id: 6,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "HAIR DRESSING",
                description: "",
                price: "ON REQUEST",
                duration: ""
            },
            {
                id: 7,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "MAKEUP ARTIST",
                description: "",
                price: "ON REQUEST",
                duration: ""
            },
            {
                id: 8,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "BRIDAL HAIRDRESSING",
                description: "",
                price: "ON REQUEST",
                duration: ""
            },
            {
                id: 9,
                category: "Wellness",
                categoryDetail: "FacialsBeauty",
                title: "BRIDAL MAKEUP ARTIST",
                description: "",
                price: "ON REQUEST",
                duration: ""
            }
        ], 
        isModal: true
    },
    {
        id: 3,
        category: "Wellness",
        anchor:"PersonalTrainer",
        imgUrl: require('../content/img/wellness/personal-trainer.jpg'),
        imgMobile: require('../content/img/wellness/personal-trainer-mobile.jpg'),
        title: "PERSONAL TRAINER",
        positionBackground: "center",
        description: "Our concierge can arrange a private training experience at your villa with a professional personal trainer. Training sessions are personalized to your goal.",
        price: "",
        buttonText: "Details",
        buttonUrl: "/reservation-form",
        categoryDetail: "PersonalTrainer",
        detail:[
            {
                id: 1,
                category: "Wellness",
                categoryDetail: "PersonalTrainer",
                title: "PERSONAL TRAINER",
                description: "",
                price: "€80,00 PER PERSON",
                duration: "DURATION: 60 MINUTES"
            }
        ],
        isModal: true
    },
    // #endregion Wellness
    
    // #region In-Villa
    {
        id: 1,
        category: "In-Villa",
        imgUrl: require('../content/img/inVilla/in-villa-private-chef.jpg'),
        imgMobile: require('../content/img/inVilla/in-villa-private-chef-mobile.jpg'),
        anchor:"InVillaPrivateChef",
        title: "In-Villa Private Chef",
        positionBackground: "top",
        description: "Savour a private dining experience with a personal Chef in the comfort of your villa with a curated Italian menu tailored to your preference. <br><br> An al-fresco setting under the stars with exclusive panoramic sea views, century-old olive trees and Mediterranean gardens is an experience that will fill your senses and your heart.",
        price: "FROM €600,00",
        buttonText: "Book your experience",
        buttonUrl: "/reservation-form",
        detail: [],
        isModal: false
    },
    {
        id: 5,
        category: "In-Villa",
        anchor:"InVillaGourmetDinner",
        imgUrl: require('../content/img/inVilla/in-villa-gourmet-dining.jpg'),
        imgMobile: require('../content/img/inVilla/in-villa-gourmet-dining-mobile.jpg'),
        title: "IN-VILLA GOURMET DINING",
        positionBackground: "top",
        description: "In-Villa Gourmet Dining is a fine-dining experience within the comfort of your private villa. Immerse yourself in our villa's sea-view terraces and gardens, surrounded by lemon trees and olive groves, for an intimate dinner prepared by our Chef Gennaro Fontana. Savor contemporary interpretations of Italian classics and seafood delicacies.",
        price: "FROM €105,00",
        buttonText: "RESERVE EXPERIENCE",
        buttonUrl: "/reservation-form",
        detail: [],
        isModal: false
    },
    {
        id: 2,
        category: "In-Villa",
        anchor:"InVillaPizzaChef",
        imgUrl: require('../content/img/inVilla/in-villa-pizza-chef.jpg'),
        imgMobile: require('../content/img/inVilla/in-villa-pizza-chef-mobile.jpg'),
        title: "IN-VILLA PIZZA CHEF",
        positionBackground: "top",
        description: "Feeling Italy offers a new take on Mediterranean cuisine bringing the finest traditions of <br> pizza-making to your villa. <br><br> Our pizza chefs created a menu of pizzas made with premium seasonal ingredients produced by local farmers emphasizing the region’s local identity and culinary heritage.",
        price: "FROM €300,00",
        buttonText: "Book your experience",
        buttonUrl: "/reservation-form",
        detail: [],
        isModal: false
    },

    
    // {
    //     id: 3,
    //     category: "In-Villa",
    //     anchor:"SunsetLoungeAndMixology",
    //     imgUrl: require('../content/img/culinaryExperiences/sunset-lounge-mixology.jpg'),
    //     imgMobile: require('../content/img/culinaryExperiences/sunset-lounge-mixology-mobile.jpg'),
    //     title: "SUNSET LOUNGE AND MIXOLOGY",
    //     positionBackground: "center",
    //     description: "Relax in the comfort of your villa open-air lounge with evocative sunset views over the Mediterranean Sea and the stunning countryside. <br><br> Savour signature cocktails prepared by your dedicated mixologist paired with seasonal canapes in al-fresco settings and lemon tree-shaded pool decks. From a private mixology experience to an intimate Mediterranean dinner prepared by our local chefs, you will embark on an authentic culinary experience unlike any other.",
    //     price: "FROM €105.00 per person <br> Minimum 6 persons",
    //     buttonText: "Book your experience",
    //     buttonUrl: "/reservation-form",
    //     detail: [],
    //     isModal: false
    // },
    
    {
        id: 4,
        category: "In-Villa",
        anchor:"InVillaCookingClass",
        imgUrl: require('../content/img/inVilla/in-villa-cooking-class.jpg'),
        imgMobile: require('../content/img/inVilla/in-villa-cooking-class-mobile.jpg'), 
        title: "IN-VILLA COOKING CLASS",
        positionBackground: "top",
        description: "Create a deeper connection with the land and discover the art of cooking authentic Italian cuisine from experienced local chefs in the comfort of your villa.<br><br> Enjoy a private hands-on cooking class and learn traditional and seasonal recipes indoors or in the villas open-air kitchens with sweeping Mediterranean views. Our menus are crafted with locally grown and fresh ingredients and can be tailored to our guests’ tastes and wishes. After the cooking class, gather with your closest friends and family for an intimate dining experience, full of unforgettable memories.",
        price: "FROM €105,00",
        buttonText: "Book your experience",
        buttonUrl: "/reservation-form",
        detail: [],
        isModal: false
    },
     // #region Bespoke Service
    {
        id: 2,
        category: "In-Villa",
        anchor:"PersonalGroceryShopper",
        imgUrl: require('../content/img/inVilla/personal-grocery-shopper.jpg'),
        imgMobile: require('../content/img/inVilla/personal-grocery-shopper-mobile.jpg'),
        title: "PERSONAL GROCERY SHOPPER",
        positionBackground: "top",
        description: "Have your groceries delivered at any time prior to or during your stay.<br> Our personal grocery shopper will source the finest food and beverage based on guests’ desires and taste. Please note all items are subject to availability. <br><br> Our Personal Grocery Shopper service is available only for guests staying at Feeling Italy’s properties.",
        price: "",
        buttonText: "Submit your grocery list",
        buttonUrl: "mailto:concierge@feelingitaly.com",
        detail: [],
        isModal: false , 
    },
    {
        id: 1,
        category: "In-Villa",
        anchor:"InVillaBreakfast",
        imgUrl: require('../content/img/inVilla/in-villa-breakfast.jpg'),
        imgMobile: require('../content/img/inVilla/in-villa-breakfast-mobile.jpg'),
        title: "IN-VILLA BREAKFAST",
        positionBackground: "top",
        description: "Start your day with local breakfast favorites in the privacy of your villa. <br><br> Experience the flavors of the Mediterranean with freshly prepared morning bakeries, pastries and breads served with seasonal jams, marmalade and butter produced by local farmers. In-villa breakfast delivery is available from Tuesday to Sunday.",
        price: "",
        buttonText: "Book your experience",
        buttonUrl: "/reservation-form",
        detail: [],
        isModal: false
    },
    // #endregion Bespoke Services
    // #endregion In-Villa
    


    
    
  
]


export default SliderPageCategory;